const en = {
  pageTitle: "Laava Scanner",
  pageDescription: "Scan your Laava Smart Fingerprint™ to ensure your product is genuine.",
  loading: "Loading",
  startingScanner: "Starting scanner",

  privacyPolicy: "By using Laava, you agree to our",
  cookiesPolicy: "Cookies policy",
  privacyAndCookies: "Privacy & Cookies",
  termsOfUse: "Terms of Use",
  accept: "Accept",
  copyrightContent:
    "LAAVA, SMART FINGERPRINTS, FINGERPRINTS, AUTHENTICATION OF THINGS, the Laava check, the Laava logo, and LAAVA 物联验证 are trademarks of Laava ID Pty Ltd.",

  titleWarningAllowCamera: "Please allow camera access.",
  bodyWarningAllowCamera:
    "This web scanner uses the camera to scan Fingerprints. Allow permission to access the camera to try again.",

  titleWarningSupportedBrowser: "Please open {{browser}} to scan your Fingerprint.",
  bodyWarningSupportedBrowser: "The browser you are using is not currently supported.",

  activateTheCameraOn: "Activate the camera on {{device}}.",
  thisWebscannerUsesTheCamera: "This web scanner uses the camera to scan Fingerprints.",

  scanNow: "Scan now",
  refresh: "Refresh",
  refreshThePage: "Refresh the page",
  loadAgain: "Load scan.laava.id again.",
  selectAllowWhenAsked: "Select allow when asked for camera access",
  whenAskedSelectAllow: "When asked “scan.laava.id Would Like to Access the Camera” select allow.",

  positionTheCode: "Ensure code is in focus and centered",
  moveCloserToQR: "Move closer to the QR code",
  moveFurtherFromQR: "Move further from the QR code",
  centerQRCode: "Center the QR code",
  unblurQrCode: "QR code is too blurry",
  goodQrCode: "Good QR code",
  badQrCode: "No QR Code detected",
  qrCodeError: "QR Code error",

  seeSupportedBrowsers: "Launch browser",

  supportedBrowsersPageTitle: "This scanner requires a browser with camera support.",
  supportedBrowsersPageBody: "Your device operating system will determine which web browsers have camera support.",
  howUnblockCamera: "How to unblock the camera",
  help: "Help",
  supportedBrowsersPageHelpBody: "If you have questions or concerns, please contact us.",
  contactUs: "Contact us",

  goToBrowserSettings: "Go to browser settings",
  findSettingBrowser: "You can find settings by tapping the three dots in the top right corner of the browser.",
  selectSiteSettings: "Select site settings",
  foundAdvanced: "This can be found under the subheading advanced.",
  selectCamera: "Select camera",
  selectWebsite: "Select ‘https://scan.laava.id’",
  changePermission: "Change the permission to access your camera, to allow.",
  returnToWebscanner: "Return to the web scanner to scan.",

  lookForLaava: "Look for Laava.  ",
  simplyScan: "Simply Scan.",
  shopWithAssurance: "Shop with Assurance.",
  laavaEnablesBrands:
    "Laava enables brands to deliver you assurance about the quality of their products using new, secure technology developed with the CSIRO.",

  aboutLaava: "About Laava",
  knowYouReGetting: "Know you’re getting the real thing and not a substitute",
  laavaIsANewKind:
    "Laava is a new kind of barcode that can help ensure the product you’re paying for is the genuine real thing and keep you safe from lesser quality imitations.",

  everyFingerprint: "Every Fingerprint is completely unique and unlike any other",
  aFingerprintDoes:
    "A Fingerprint does so much more than just indicate what product or batch you're holding. It is an individual, completely unique Fingerprint for the exact product in your hand.",

  seeBeyondTheLabel: "See beyond the label and know more about your products",
  scanningAllowsYou:
    "Scanning allows you to see more information than just what’s on the label. Find out specific information about that exact item such as where it was produced or when it was packaged.",

  ourCompany: "Our company",
  laavaWasFounded:
    "Laava was founded in Australia in 2016. The concept and technology solution has been developed by world leading experts in the field of optics, blockchains, marketing, consumer insights.",

  needHelpScanning: "Need help scanning?",

  youCanFindDetails: "You can find details about to scan, along with answers to common problems, in our help guide.",

  getHelp: "Get help with scanning",

  howScan: "How to scan",
  problemsWithTheFingerprint: "Problems with the Fingerprint",
  commonProblems: "Common problems",
  getResults:
    "To get the best results, these tips can help you overcome some common issues that may affect the quality of your scan.",
  readyToScan: "Ready to scan a Fingerprint?",
  needMoreHelp: "Need more help?",
  scanFingerprint: "Scan your Fingerprint",
  bringFingerprint:
    "Bring the Fingerprint into focus within the camera view, and hold in place until the Fingerprint is detected. This may take a few moments depending on your connection speed.",

  locateSmartFingerprint: "Locate your Smart Fingerprint™",
  fingerprintsCanBeFound: "Fingerprints can be found on a variety of everyday products.",
  alignTheFingerprint: "Align the Fingerprint using the guide",
  alignTheGuide: "Align the guide to the inner and outer rings of the Fingerprint.",
  snapPhoto: "Snap a photo",
  getYourFingerprint: "Get your Fingerprint in focus and keep your hand steady.",

  light: "Light",
  likeAnyCamera:
    "Like any camera, the best results are in good light. Ensure your Fingerprint is well lit and watch for glare.",
  keepYourHands: "Keep your hands steady",
  tryKeepYourHand: "Try to keep your hand and camera still when taking the photo, so the image is clear.",
  considerTheAngle: "Consider the angle",
  scanYourFingerprint:
    "Scan your Fingerprint in a position that is front on to the camera, and filling most of the view (while remaining in focus). ",
  watchOutForShadow: "Watch out for shadows",
  whenScanning: "When scanning, make sure your phone isn’t casting a dark shadow over the Fingerprint.",

  haveAnyQuestion: "Have any questions or feedback? We’d love to hear from you. ",
  email: "Email",

  startingCamera: "Starting camera",
  searching: "Scanning",
  analysing: "Analysing",
  sending: "Sending",
  sendingForVerification: "Sending for Verification",
  mightTakeAFewMoments: "It might take a few moments",
  placeCodeWithinFrame: "Place the Fingerprint within the frame to scan",
  needHelp: "Need help?",

  googlePrivacyPolicy: "Google Privacy Policy",
  laavaPrivacy: "Laava Privacy",
  laavaHasAPrivacy: `Laava has a {{ manifesto }}
          that explains our policies that affect you and your privacy as
          a user of Laava Fingerprints. Separately, we also have a
          {{ policy }} that only
          applies in the limited circumstances in which we have to collect
          personal information.
   `,
  privacyManifesto: "Privacy Manifesto",
  corporatePrivacy: "Corporate Privacy Policy",

  laavaWebscannerTerm: "Laava – Webscanner Terms and Conditions",
  general: "General",
  websiteUse: "Website use",
  intellectualProperty: "Intellectual property",
  limitionOfLiability: "Limitation of Liability",
  indemnity: "Indemnity",
  otherWebsites: "Other websites and third parties",
  entireAgreement: "Entire agreement",
  invalidity: "Invalidity and enforceability",
  privacy: "Privacy",
  applicableLaw: "Applicable law",
  howToContact: "How to contact us",

  termsP00: `Thank you for accessing the Laava Website (the Website). The Website is 
            owned and operated by Laava ID Pty Ltd (A.B.N. 99 617 775 578) (Laava).`,

  termsP01: `Your use of the Website is governed by these Terms of Use. By accessing 
            and using the Website you agree to by bound by these Terms of Use.`,

  termsP02: `Laava may change part or all of these Terms of Use at any time. The most 
            current Terms of Use will always be posted on the Website. Your subsequent 
            or continued use of the Website will constitute your acceptance of 
            any changes made to these Terms of Use. If you object to these Terms 
            of Use, or to any changes made to them, you should cease use of the 
            Website.`,

  termsP03: "These terms were last updated on 26 November 2018.",

  termsP1: "You may only use the Website or any Content for non-commercial purposes.",

  termsP2: `The information contained on the Website is for general information 
            purposes only.`,

  termsP3: `The Website and the information, links, documents or related graphics 
            contained on the Website (together, the Content) are provided ‘as is’. 
            Any reliance you place on the Content is strictly at your own risk. You 
            need to make your own enquiries to determine whether the Content is 
            appropriate for your intended use.`,

  termsP4: `While Laava makes every reasonable effort to ensure that the Website 
            is available and fully operational at all times, your access to the 
            Website may be suspended without notice in the case of system failure, 
            maintenance or any reason beyond Laava’s control. `,

  termsP5: "Laava may change or discontinue, either temporarily or permanently, the:",

  termsP6: "Website, or any Content, feature or service on the Website; or",

  termsP7: "the path or location of a link,",

  termsP8: `at any time without notice. Laava disclaims any liability in connection 
            with these, or similar, changes, discontinuances or interruptions of 
            the Website, or any part of the Website. `,

  termsP9: `Laava reserves the right to terminate your access to the Website and 
            prevent further access without warning or notice in response to breaches 
            of these Terms of Use by yourself, breaches of any applicable laws, or 
            suspicious activity.`,

  termsP10: "When using the Website you agree not to:",

  termsP11: "provide any false, misleading or deceptive information;",

  termsP12: `use the Website in a way that violates, or that may violate, any 
            law or regulation;`,

  termsP13: `use the Website in a way that may cause Laava to be in violation of 
            any law or regulation;`,

  termsP14: "use the Website in a way that reflects unfavourably on Laava or any other third party;",

  termsP15: `collect information about other users of the Website for any purpose 
            other than as expressly authorised by us;`,

  termsP16: `take any action that, in Laava’s opinion, imposes an unreasonable 
            burden on the Website’s infrastructure; `,

  termsP17: `distribute a computer virus or any similar program or code that 
            may disrupt or disable the Website;`,

  termsP18: `use a device, any software or process, or other automated means 
            to retrieve, scrape, copy or index the Website or any Content for 
            any purpose; or`,

  termsP19: `retrieve, scrape, copy or index the Website or any Content for the purpose 
            of creating a database of real estate information. `,

  termsP20: `The Website and all intellectual property in the Content 
            on the Website, including (without limitation) 
            any text, graphics, photographs, logos or trade 
            marks is owned by, or licensed to, Laava, unless 
            indicated otherwise.`,

  termsP21: `Except where necessary for viewing the Content 
            on the Website, where permitted by law, 
            or where you have obtained Laava’s express 
            written permission, you may not reproduce, recompile, 
            decompile, disassemble, reverse engineer, create 
            derivative works from, perform, modify, adapt, 
            publish, store, use, transmit electronically or 
            otherwise, distribute, or in any other way exploit, 
            by any means in whole or in part the Website, 
            or the Content on the Website for purposes other 
            than your own personal, non-commercial use.`,

  termsP22: "Your use of the Website is undertaken at your own risk.",

  termsP23: `To the maximum extent permitted by law, 
            Laava disclaims all representations, 
            guarantees or warranties, express or 
            implied, including, but not limited to: `,

  termsP24: `the completeness, accuracy, reliability, suitability or availability 
            with respect to the Website or the 
            Content;`,

  termsP25: `that the Website and the Content will be free of errors, or that 
            defects will be corrected;`,

  termsP26: `that your access to the Website will be uninterrupted, secure or 
            error free;`,

  termsP27: "that the Website is free of viruses or any other harmful components; and ",

  termsP28: `the quality, accuracy, or fitness for purpose of any linked websites, or 
            third party material. `,

  termsP29: `To the maximum extent permitted by law, 
            Laava and its directors, employees and 
            agents, are not liable to you for any 
            loss or damage of any kind (including consequential 
            loss) howsoever caused, whether in contract, 
            tort including negligence, statute or otherwise 
            arising in connection with the Website or the 
            Contents.`,

  termsP30: `Nothing in these Terms of Use is intended to exclude, restrict or modify rights 
            which you may have under any law 
            (including in Australia, the Australian 
            Consumer Law) which may not be excluded, 
            restricted or modified by agreement. If 
            these Terms of Use are governed by the 
            Australian Consumer Law, our liability to 
            you for failure to comply with a consumer 
            guarantee in respect of any services is limited 
            to the re-supply of the services or payment 
            for such re-supply.`,

  termsP31: `You indemnify Laava in respect of any liability 
            incurred by Laava for any losses, liabilities 
            or damages, expenses, including without limitation, 
            reasonable legal and accounting fees, howsoever 
            caused, which is suffered by Laava as a result 
            of your breach of these Terms of Use or your use 
            of the Website.`,

  termsP32: `The Website may contain links to third party websites, advertisements 
            or information on those websites.`,

  termsP33: `Laava has no control over the nature, 
            content and availability of third party 
            websites. The inclusion of any links to 
            other websites does not necessarily 
            imply a sponsorship, endorsement, or 
            approval of those websites or the views 
            expressed within them.`,

  termsP34: `Laava takes no responsibility for the 
            content on those websites. Any use of 
            third party websites and information is at 
            your own risk.`,

  termsP35: `These Terms of Use represent the entire 
            agreement between you and Laava concerning 
            your use and access to the Website and your 
            use and access to the Content on it. No 
            other term is to be included in these Terms 
            of Use except where it is required to be 
            included by any legislation of the Commonwealth 
            of Australia or any State or Territory of 
            Australia. All implied terms except those 
            implied by statute and which cannot be expressly 
            excluded are expressly excluded.`,

  termsP36: `If any provision of these Terms of Use 
            is invalid under the law of any jurisdiction, 
            then the provision is enforceable in that 
            jurisdiction to the extent that it is not 
            invalid, whether it is in severable terms or 
            not.`,

  termsP37: `All personal information Laava collects from 
            you will be handled in accordance with the 
            Privacy Act 1988 (Cth) and the {{policy}}`,

  termsP38: `For more information on how Laava handles your personal information 
            when you scan Laava Fingerprints, refer to 
            {{manifesto}}`,

  termsP39: `Laava reserves the right at any time and from time to time to modify,
             discontinue either temporarily or permanently the Website Scanner, or
             any part thereof, for any reason. Laava disclaims any liability as a
             result of any discontinuance or interruption of the Website Scanner,
             or any part thereof`,

  termsP40: "You can contact Laava via:",

  contact: "Contact",
  privacyOfficer: "Privacy Officer",
  address: "Address",
  phone: "Phone",

  laavaPrivacyManifesto: "Laava Privacy Manifesto",
  privacyManifestoIntroduction: "Laava – Privacy Manifesto",
  ourApproach: "Our approach to your privacy",
  whatInformationScan: "What information is collected when I scan a Laava Fingerprint?",
  whatInformationDetect: "What information is collected when we detect suspicious activity?",
  howDoesLaava: "How does Laava use cookies?",
  sharingInformation: "Sharing Information",

  privacyManifestoP1: `This document is our Privacy Manifesto which outlines our policy regarding the collection of
                       information when you scan a Laava Smart Fingerprint using a browser or WeChat Laava scanner.`,

  privacyManifestoP2: `We have a separate {{ link }} that sets out how we handle your personal information in
                       other business circumstances, such as if you apply for a position with us, if you are a potential
                       investor, representative of a partner, customer, supplier or service provider of ours, or if you
                       contact our help desk or submit a question or query with us.`,

  privacyManifestoP3: `We don’t wish to collect any of your personal information when you scan Laava Smart Fingerprints.
                       Respecting your privacy is our key concern, which is why we don’t require names, log-ins, phone
                       numbers, emails or any other information that identifies you personally.`,

  privacyManifestoP4: `Scanning of Laava Smart Fingerprints is at the core of everything we do. We want to provide a
                       safe, private and worry-free environment for everyone who scans a Laava Smart Fingerprint.
                       Scanning Laava Smart Fingerprints means no hassle and no prying eyes.`,

  privacyManifestoP5: `We think so many companies can do better with regards to consumer privacy and we want to lead
                       the way. Just because you can collect data, doesn't mean you should.`,

  privacyManifestoP6: `When you scan a Laava Smart Fingerprint, we capture a picture of your scan and other scan
                       information, or meta-data, required to authenticate the Laava Smart Fingerprint and update its
                       status record. That meta-data may include the URL from which the scan originated, the scan
                       location (usually derived from an IP address or GPS location), and the time of each scan. We also
                       assign and record an anonymised scanner ID with each scan and may use limited device
                       information such as browser and camera settings.`,

  privacyManifestoP7: `Importantly, all the information we collect when you scan a Laava Smart Fingerprint is anonymised
                       and can not easily be used to identify you (Anonymised Information”). If the picture of your scan
                       includes information that could be used to identify you, we will not seek to do so.`,

  privacyManifestoP8: `If you scan a Laava Smart Fingerprint in our WeChat mini-programme, you will also be subject to
                       WeChat’s privacy policy.`,

  privacyManifestoP9: `To ensure Laava is an effective tool against counterfeiting and fraud, and to keep your scanning
                       environment safe and maintain a high quality of service, performance and security, we use a range
                       of methods to determine whether the scanning activity of an individual Laava Smart Fingerprint is
                       suspicious. When we detect suspicious activity, we may conduct a thorough analysis of the scan
                       activity and meta-data, as well as pass on this information to our customers and partners (together
                       “Customers”).`,

  privacyManifestoP10: `Laava uses browser cookies on its website. A browser cookie is a small piece of data that is stored
                        on your device to help websites remember things about your browsing. Cookies help us see which
                        parts of our website are the most popular, which allows us to improve your experience.`,

  privacyManifestoP11: `To help improve our website, certain information may be collected as statistical information and
                        may include IP address, browser type, language and access times. The tools used on our website
                        record information in a way that does not identify you as an individual. Your browser or device may
                        allow you to block, limit the use of, or remove cookies.`,

  privacyManifestoP12: `When you scan Laava Smart Fingerprints using a browser-based scanner, cookies may be used,
                        but your scan history will not be stored.`,

  privacyManifestoP13: `Our website may also use third party website tools from time to time, for example Google Analytics
                        and online survey tools. For more about how Google collects and processes data, please see
                        Google’s privacy policy and their information at {{ link }}`,

  privacyManifestoP14: `Customers are obviously interested in analysing the Laava Smart Fingerprints scanning records
                        associated with their items. We do share scanning information about Laava Smart Fingerprints
                        with Customers, usually in an aggregated form. When we share information with Customers, we
                        are only telling them what they need to know, such as where, when and how many times their
                        Laava Smart Fingerprint was scanned. Since we don’t collect your personal information, we can’t
                        share that with them.`,

  privacyManifestoP15: `We also allow Customers to engage with you on a personal basis when you scan a Laava Smart
                        Fingerprint. As a part of this engagement, Customers may request personal information from you.
                        We think that’s great, but at Laava sharing any personal information is always done on a
                        transparent and opt-in basis. You’re always in charge and able to choose what you share.`,

  privacyManifestoP16: `Laava won’t see or collect your personal information, instead any personal information you share
                        will be collected and handled by our customers in accordance with their privacy policies, which 
                        you will be asked to accept.`,

  privacyManifestoP17: `Laava may share any information you provide when it believes release is appropriate for
                        compliance with the law, to facilitate court proceedings or protect the rights, property, or 
                        safety of Laava, the users of this website and others.`,

  laavaPrivacyPolicy: "Laava Privacy Policy",
  privacyPolicyT1: "About this Corporate Privacy Policy",
  privacyPolicyT2: "When do we collect your personal information?",
  privacyPolicyT3: "What personal information do we collect and handle?",
  privacyPolicyT4: "Why do we collect your personal information?",
  privacyPolicyT5: "Who might we disclose your personal information to?",
  privacyPolicyT6: "What if we can’t collect your personal information?",
  privacyPolicyT7: "Visitors to our website and cookies",
  privacyPolicyT8: "How do we protect your personal information?",
  privacyPolicyT9: "Direct marketing communications",
  privacyPolicyT10: "How can you access and correct your personal information?",
  privacyPolicyT11: "How can you make a privacy complaint?",
  privacyPolicyT12: "How to contact us",

  privacyPolicyP1: `We think so many companies can do better with regards to consumer
                    privacy and we want to lead the way. Just because you can collect
                    data, doesn’t mean you should. We value and respect your privacy and
                    take the protection of your personal information seriously.`,

  privacyPolicyP2: `This Corporate Privacy Policy is NOT about what happens to you or
                    your personal information when you scan a Laava Fingerprint. Our
                    policy of not collecting personal information when scanning Laava
                    Fingerprints is covered in our separate {{ link }}`,

  privacyPolicyP3: `This Corporate Privacy Policy sets out how Laava ID Pty Ltd
                    (ABN 99617 775 578) (Laava, we, our, us) handles your personal information in
                    the limited circumstances in which we have to collect personal
                    information, such as if you apply for a job with us, or seek to invest
                    in, or work with Laava (as a representative of a partner, customer,
                    supplier or service provider), or if you contact our help desk or
                    submit query.`,

  privacyPolicyP4: `It outlines the personal information we collect about you, when and
                    how we use it. It also outlines your privacy rights as an individual
                    and how to contact us if you have any privacy concerns. This Privacy
                    Policy may change from time to time and so you may wish to review it
                    regularly.`,

  privacyPolicyP5: `As set out in our Privacy Manifesto, we do not collect any personal
                    information in connection with the scanning of a Laava Fingerprint.`,

  privacyPolicyP6: `We also do not see or collect your personal information when you
                    choose to share personal information with the owners of brands that
                    use our Laava Fingerprints (Brands). This is also covered in our
                    Privacy Manifesto.`,

  privacyPolicyP7: `However, there are some limited circumstances in which we need to
                    collect your personal information directly from you, including when you:`,

  privacyPolicyP8: `communicate with us by telephone, email, online (including through
                    our website or help desk), or in person;`,

  privacyPolicyP9: "when you apply for a position with us;",

  privacyPolicyP10: "when you apply to become an investor; or",

  privacyPolicyP11: `when you interact with us as a potential or ongoing customer,
                     partner, supplier or service provider.`,

  privacyPolicyP12: `Personal information is any information or opinion (whether true or
                     not, and whether recorded in a material form or not) about you as an
                     identified individual, or an individual who is reasonably
                     identifiable. The types of personal information we collect and handle
                     may include:`,

  privacyPolicyP13: `contact information, such as your name, address, phone number, email
                     and/or IP address;`,

  privacyPolicyP14: `information about your role within your organisation, if you are an
                     existing or potential partner, customer, supplier or service
                     provider of ours;`,

  privacyPolicyP15: `financial and other relevant details you share with us if you apply
                     to be an investor;`,

  privacyPolicyP16: `if you apply for a position with us, we might collect information
                     about your qualifications, skills experience, character, screening
                     checks and employment history; and`,

  privacyPolicyP17: `other information you provide to us via our help desk (or in any
                     query you send to us).`,

  privacyPolicyP18: `We collect, hold, use and disclose personal information primarily to
                     respond to queries, to consider your application for a position with
                     us, or to consider you as an investor, customer, supplier or partner
                     of ours. We may also collect your personal information for related
                     reasons, including:`,

  privacyPolicyP19: `administrative purposes including record-keeping and internal
                     reporting;`,

  privacyPolicyP20: "to communicate with you;",

  privacyPolicyP21: "for marketing purposes; and",

  privacyPolicyP22: "to comply with applicable laws and regulations.",

  privacyPolicyP23: `In the limited circumstances listed in section 2 above where we have
                     collected your personal information, we may disclose your personal
                     information to third parties. The types of third parties with whom we
                     exchange personal information include:`,

  privacyPolicyP24: `our service providers, including accounting, auditing, legal,
                     banking, payment, delivery, data hosting, research, recruitment,
                     insurance, superannuation, payroll, training and technology
                     services;`,

  privacyPolicyP25: `for people who apply to work with us, referees and screening check
                     providers (e.g. for background, eligibility to work, suitability and
                     criminal record checks); and`,

  privacyPolicyP26: `to law enforcement agencies and other organisations where required
                     or permitted by law.`,

  privacyPolicyP27: `We will not disclose your personal information to any overseas
                     recipients. However, we may store your personal information with
                     secure data hosting providers such as Google, who may store that
                     information in the following locations: ({{link}}).
                     Where possible we will require that your personal information
                     remain stored in Australia. If we need to disclose your personal
                     information to any overseas recipients, we will update this Privacy
                     Policy by specifying the countries in which they are located.`,

  privacyPolicyP28: `If you do not provide us with the personal information when required,
                     we may not be able to:`,

  privacyPolicyP29: "consider your application to join us as an employee;",

  privacyPolicyP30: `consider your application to become an investor, customer, partner;
                     supplier or service provider; and`,

  privacyPolicyP31: "respond to your queries or requests for information.",

  privacyPolicyP32: `A browser cookie is a small piece of data that is stored on your
                     device to help websites and mobile applications remember things about
                     you. Although we don’t use cookies in the Laava mobile app, we do use
                     cookies on our website. Cookies help us see which parts of our website
                     are the most popular, which allows us to focus on improving those
                     aspects of the website that will improve your experience, and to
                     provide you with a better, more intuitive, and satisfying experience.
                     Your browser or device may allow you to block or otherwise limit the
                     use of cookies. You may also be able to remove cookies from your
                     browser.`,

  privacyPolicyP33: `To help improve the service we provide through our website, certain
                     information may be collected from your browser. This information is
                     collected as statistical information and includes the IP address,
                     browser type, language and access times. In many cases, the tools used
                     on our website record information in a way that does not identify you
                     as an individual. In other cases, information may be personal
                     information in which case this Privacy Policy will apply.`,

  privacyPolicyP34: `Our website may also use third party website tools from time to time,
                      for example Google Analytics and online survey tools. For more about
                      how Google collects and processes data, please see Google’s privacy
                      policy and their information at: ({{ link }}).`,

  privacyPolicyP35: `We take all reasonable steps to keep personal information protected
          from loss, interference, misuse or unauthorised access, modification
          or disclosure. These steps may include access controls, encryption,
          and secure premises. We store personal information in both physical
          and electronic form, sometimes with the assistance of third parties
          such as data hosting providers.`,

  privacyPolicyP36: `From time to time, if you have opted-in to receive marketing
          information from us, we may send you direct marketing communications
          regarding our services.`,

  privacyPolicyP37: `We may contact you by electronic messages (e.g. email), online (e.g.
          through our website), by mail, and by other means, unless you
          subsequently opt-out or we are subject to legal restrictions. You may
          opt-out of marketing communications, by contacting us using the
          contact details below.`,

  privacyPolicyP38: `The accuracy of the personal information we hold and use is important
          to us. We take reasonable steps to ensure that the personal
          information we handle is accurate, complete and up-to-date. To help us
          keep your personal information accurate, please let us know if there
          are any errors or changes in your personal information.`,

  privacyPolicyP39: `You can request access to and correction of the personal information
          we hold about you at any time by contacting our Privacy Officer via
          the contact details at the bottom of this Privacy Policy. In most
          cases, we can help you promptly and informally with these requests. In
          other cases, we may need to verify your identity and ask you to make
          your request in writing.`,

  privacyPolicyP40: `You can contact our Privacy Officer using the contact details at the
          bottom of this Privacy Policy if you have any concerns about how we
          have handled your personal information. We will respond to let you
          know who will be handling your matter and when you can expect a
          further response. We may request additional details from you regarding
          your concern, and we may need to engage or consult with other parties
          to investigate and deal with your issue. We will keep records of your
          request and any resolution. If you are not satisfied with the manner
          in which we have dealt with your complaint, you may contact the Office
          of the Australian Information Commissioner.`,

  privacyPolicyP41: `If you have any questions, complaints or claims in relation to the
          Website Scanner or these Terms and Conditions, you can contact Laava
          at:`,
  problemsTitle: "Problems with the Fingerprint",
  problemsIntro:
    "If the Laava Smart Fingerprint™ appears damaged or you’re having trouble scanning, you can reach out and we’ll do our best to help you resolve the problem.",
  tryScanningAgain: "Try scanning again",
  emailUs: "Email us",
  troubleDetectingFingerprint: "We’re having trouble detecting a Fingerprint",
  makeSureFingerprint: "Make sure the Fingerprint is <strong>well lit</strong> and <strong>in focus</strong>",
  ensureInternet: "Ensure your <strong>internet connection</strong> is good",
  tryAgain: "Try again",
  logIn: "Log In",
  account: "Account",
  emailAddress: "Email Address",
  password: "Password",
  submit: "Submit",
  thereWasAProblemLoggingIn: "There was a problem logging in. Please try again.",
  loggedInAs: "Logged in as",
  logOut: "Log Out",
}

export default en
