const zh = {
  pageTitle: "Laava Scanner",
  pageDescription: "扫描您的 Laava Smart Fingerprint™ 以确保您的产品为正品",
  loading: "载入中",
  startingScanner: "正在启动扫描仪",

  privacyPolicy: "使用Laava即表示您同意我们的",
  cookiesPolicy: "Cookies政策",
  privacyAndCookies: "隐私和 Cookies",
  termsOfUse: "使用条款",
  accept: "接受",
  copyrightContent:
    "LAAVA、SMART FINGERPRINTS、FINGERPRINTS、AUTHENTICATION OF THINGS、Laava 检查、Laava 徽标和 LAAVA 物联验证是 Laava ID Pty Ltd. 的商标。",

  titleWarningAllowCamera: "请允许相机访问。",
  bodyWarningAllowCamera: "本网站使用手机相机进行扫描。允许相机访问，然后再次尝试。",

  titleWarningSupportedBrowser: "请打开{{browser}}扫描您的产品指纹。",
  bodyWarningSupportedBrowser: "目前不支持您使用的浏览器。",

  activateTheCameraOn: "在{{device}}上激活相机。",
  thisWebscannerUsesTheCamera: "此网络扫描仪使用相机扫描产品指纹。",

  scanNow: "立即扫描",
  refresh: "刷新",
  refreshThePage: "刷新页面",
  loadAgain: "再次加载scan.laava.id。",
  selectAllowWhenAsked: "请求相机访问时请选择允许",
  whenAskedSelectAllow: "当看到“scan.laava.id想要访问相机”时，请选择允许。",

  positionTheCode: "将码放入相机框内",
  moveCloserToQR: "请靠近二维码",
  moveFurtherFromQR: "请远离二维码",
  centerQRCode: "将二维码居中",
  unblurQrCode: "二维码模糊",
  goodQrCode: "二维码有效",
  badQrCode: "未检测到二维码",
  qrCodeError: "二维码错误",

  seeSupportedBrowsers: "启动浏览器",

  supportedBrowsersPageTitle: "此掃描儀需要支持相機的瀏覽器。",
  supportedBrowsersPageBody: "您的設備操作系統將確定哪些瀏覽器具有攝像頭支持。",
  howUnblockCamera: "如何取消阻止相机",
  help: "帮助",
  supportedBrowsersPageHelpBody: "如有任何问题或疑虑，请与我们联系。",
  contactUs: "联系我们",

  goToBrowserSettings: "进入浏览器设置",
  findSettingBrowser: "点击浏览器右上角的三个点，就可以进入设置页面。",
  selectSiteSettings: "选择网站设置",
  foundAdvanced: "在“高级”选项下可找到该设置。",
  selectCamera: "选择相机",
  selectWebsite: "选择'https://scan.laava.id'",
  changePermission: "将相机访问权限更改为允许",
  returnToWebscanner: "返回网络扫描仪进行扫描",

  lookForLaava: "寻找 Laava。",
  simplyScan: "轻松扫描。",
  shopWithAssurance: "安心购买。",
  laavaEnablesBrands: "Laava 使用由 CSIRO 开发的新安全技术，确保各品牌能够提供产品质量方面的保证。",

  aboutLaava: "关于 Laava",
  knowYouReGetting: "确保您使用的是正品而非赝品。",
  laavaIsANewKind: "Laava 是一种新型条形码，有助于确保您所购买的产品为正品，防止遇到低质量仿制品。",

  everyFingerprint: "每个指纹都是独一无二的",
  aFingerprintDoes: "产品的指纹不仅对应您所持有的产品或批次。还是一个完全独特的标识，可精确匹配您持有的产品。",

  seeBeyondTheLabel: "除了标签之外，了解有关您产品的更多信息",
  scanningAllowsYou: "通过扫描查看标签以外的更多信息。找出有关该确切项目的具体信息，例如生产地点或包装时间。",

  ourCompany: "我们的公司",
  laavaWasFounded:
    "Laava 于 2016 年在澳大利亚成立。我们所有的概念和技术解决方案，均由光学、区块链、营销和消费者洞察领域的世界领先专家开发。",

  needHelpScanning: "扫描需要帮助？",

  youCanFindDetails: "我们的帮助指南提供了扫描的详细信息和常见问题解答。",

  getHelp: "获取有关扫描的帮助",

  howScan: "如何扫描",
  problemsWithTheFingerprint: " 指纹问题",
  commonProblems: "常见问题",
  getResults: "这些提示可以帮助您克服可能影响扫描质量的一些常见问题，获得最佳效果。",
  readyToScan: "准备好扫描指纹了吗？",
  needMoreHelp: "需要更多帮助？",
  scanFingerprint: "扫描您的产品指纹",
  bringFingerprint:
    "将产品指纹放在相机的聚焦位置，保持稳定，直到检测到指纹。此操作可能需要一些时间，具体取决于您的连接速度。",

  locateSmartFingerprint: "找到您的智能指纹(Smart Fingerprint) ",
  fingerprintsCanBeFound: "很多日常用品都有这样的“指纹”。",
  alignTheFingerprint: "使用指南對齊指紋",
  alignTheGuide: "將指南對準指紋的內環和外環。",
  snapPhoto: "拍照",
  getYourFingerprint: "讓你的指紋成為焦點,保持手的穩定。",

  light: "光线",
  likeAnyCamera: "像任何相机一样，光线充足才能获得最佳效果。确保您的产品指纹处光线充足，但避免出现任何刺眼的光。",
  keepYourHands: "保持雙手穩定",
  tryKeepYourHand: "拍攝照片時盡量保持手和相機靜止,這樣圖像就清晰了。",
  considerTheAngle: "考虑角度",
  scanYourFingerprint: "扫描时将指纹放在相机正面，填充大部分的扫描框（同时保持对焦）。",
  watchOutForShadow: "注意阴影",
  whenScanning: "扫描时，请确保您的手机没有在指纹上投下阴影。",

  haveAnyQuestion: "有问题或反馈？我们随时听取您的意见。",
  email: "电子邮箱",

  startingCamera: "啟動相機",
  searching: "正在扫描",
  analysing: "分析",
  sending: "正在發送",
  sendingForVerification: "验证中",
  mightTakeAFewMoments: "可能需要一些时间",
  placeCodeWithinFrame: "将指纹放在相机框内进行扫描",
  needHelp: "需要帮助？",

  googlePrivacyPolicy: "Google隐私权政策",
  laavaPrivacy: "Laava隐私",
  laavaHasAPrivacy: `Laava的{{manifesto}}阐释了我们针对 Laava Fingerprints 
                    用户及其隐私的政策。另外，我们还制定了{{policy}}，
                    该政策仅适用于我们必须收集个人信息的有限情况。`,

  privacyManifesto: "隐私声明",
  corporatePrivacy: "企业隐私政策",

  laavaWebscannerTerm: "Laava– 网站使用条款",
  general: "一般规定",
  websiteUse: "网站使用",
  intellectualProperty: "知识产权",
  limitionOfLiability: "责任限制",
  indemnity: "赔偿",
  otherWebsites: "其他网站和第三方",
  entireAgreement: "完整协议",
  invalidity: "有效性和可执行性",
  privacy: "隐私",
  applicableLaw: "适用法律",
  howToContact: "如何联系我们",

  termsP00: `感谢您访问Laava网站（本网站）。本网站由Laava ID
            Pty Ltd（澳洲公司编号99 617 775 578）（Laava）
            拥有和运营。`,

  termsP01: `您使用本网站受本使用条款管辖。访问和使
            用本网站，即表示您同意受本使用条款的约束。`,

  termsP02: `Laava可随时更改本使用条款的部分或全部内容。
            最新的使用条款将始终发布在本网站上。您随后或
            继续使用本网站，即表示您接受对本使用条款所作
            的任何更改。如果您反对本使用条款或其作出的任
            何更改，您应停止使用本网站。`,

  termsP03: "本使用条款于 2018 年 11 月 26 日最后更新。",

  termsP1: "您只能将本网站或任何内容用于非商业用途。",

  termsP2: "本网站所载的信息仅供参考。",

  termsP3: `本网站及所载的信息、链接、文件或相关图形（统称
            为“内容”）均“按原样”提供。您对内容的任何
            依赖所产生的风险完全由您自行承担。您需自
            行查询以确定内容是否适合您的预期用途。`,

  termsP4: `尽管 Laava 尽一切合理努力确保本网站在任何时
            候都可用并充分运行，但在系统故障、维护或 Laava 
            无法控制的任何原因下，您对本网站的访问可能会被
            暂停，恕不另行通知。`,

  termsP5: "Laava 可随时临时或永久更改或中止",

  termsP6: "本网站或本网站上的任何内容、功能或服务；或者",

  termsP7: "链接的路径或位置，",

  termsP8: `恕不另行通知。Laava 声明，其不承担与本网站
            或本网站任何部分的此等或类似更改、中止或中断
            有关的任何责任。`,

  termsP9: `Laava 保留在您违反本使用条款、违反任何适用法律
            或从事可疑活动时，终止您访问本网站并阻止您进一步
            访问本网站的权利，恕不另行警告或通知。`,

  termsP10: "使用本网站时，您同意不会：",

  termsP11: "提供任何虚假、误导性或欺骗性的信息；",

  termsP12: "以违反或可能违反任何法律法规的方式使用本网站；",

  termsP13: `以可能造成 Laava 违反或可能违反任何法律
            法规的方式使用本网站；`,

  termsP14: "以不利于 Laava 或任何其他第三方的方式使用本网站；",

  termsP15: `出于本公司明确授权以外的任何目的收集有关本
            网站其他用户的信息；`,

  termsP16: `采取 Laava 认为会对本网站的基础设施造成
            不合理负担的行动；`,

  termsP17: `散布电脑病毒或可能干扰或令本网站失效的任何
            类似程序或代码；`,

  termsP18: `使用设备、任何软件或程序，或其他自动手段检索、
            刮取、复制本网站或任何内容或为其编索引以用作任
            何用途；或者`,

  termsP19: `出于建立房地产信息数据库而检索、刮取、复制本
            网站或任何内容或为其编索引。`,

  termsP20: `除非另有说明，本网站及本网站内容中的所有知识
            产权，包括（但不限于）任何文本、图形、照片、徽标
            或商标均归 Laava 所有或许可给 Laava。`,

  termsP21: `除了必须查看本网站上的内容、在法律允许的情况下，
            或者您获得 Laava 的明确书面许可，否则您不得复制、
            重新编译、反编译、反汇编、反向工程、创造衍生作品、
            执行、修改、改编、发布、存储、使用、以电子或其他
            方式传输、分配，或以任何其他方式、通过任何手段将
            全部或部分本网站或本网站上的内容用于个人及非商业
            用途以外的任何目的。`,

  termsP22: "您使用本网站的风险由您自行承担。",

  termsP23: `在法律允许的最大范围内，Laava 不作任何明示或
            默示陈述、保证或担保，包括但不限于：`,

  termsP24: "本网站或内容的完整性、准确性、可靠性、适用性或可用性；",

  termsP25: "本网站及内容不存在任何错误，或缺陷将会得到纠正；",

  termsP26: "您访问本网站将不受干扰、安全或无错误；",

  termsP27: "本网站没有病毒或任何其他有害成分；以及",

  termsP28: "任何链接网站或第三方材料的质量、准确性或适用性。",

  termsP29: `在法律允许的最大范围内，Laava 及其董事、
            雇员和代理人对于因合同、侵权行为（包括疏忽）、
            法令或本网站或内容引起的与之相关的任何类型损失
            或损害（包括后续损失），均不向您承担任何责任。`,

  termsP30: `本使用条款中的任何内容均无意排除、限制或修改
            您在任何法律（包括在澳大利亚的《澳大利亚消费者法》）
            项下可能享有的权利，该等权利不得通过协议予以排除、
            限制或修改。如果本使用条款受《澳大利亚消费者法》
            管辖，我们对您就任何服务未遵守消费者保证的责任仅限
            于服务的再供应或此类再供应的付款。`,

  termsP31: `您应就 Laava 因您违反本使用条款或使用本网站
            而遭受的任何损失、责任或损害、费用（包括但不限于
            合理的律师费和会计费），向 Laava 作出赔偿。`,

  termsP32: "本网站可能包含指向第三方网站、广告或此等网站上信息的链接。",

  termsP33: `Laava 无法控制第三方网站的性质、内容和可用性。
            加入其他网站的任何链接并不一定意味着对此等网站或
            其中所表达的观点的赞助、认可或赞同。`,

  termsP34: `Laava 对于此等网站上的内容不承担任何责任。使用
            第三方网站和信息的风险由您自行承担。`,

  termsP35: `本使用条款代表您与 Laava 之间关于您使用和访问
            本网站以及本网站内容的完整协议。本使用条款不得包
            含任何其他条款，澳大利亚联邦或澳大利亚任何州或领
            土的任何法律要求纳入的除外。除成文法默示且不能明
            确排除的条款外，所有默示条款均明确予以排除。`,

  termsP36: `如果本使用条款的任何规定根据司法管辖区的法律属
            无效规定，则只要该规定有效，其在该司法管辖区就可
            执行，不论该规定是否处于可分割条款中。`,

  termsP37: `Laava 向您收集的所有个人信息均根据《1988 年隐私法》
            （联邦法案）和 Laava 隐私政策（详见{{policy}}）
            予以处理。`,

  termsP38: `关于 Laava 在您扫描 Laava 指纹时如何处理您的个
            人信息的详情，参见此处的 Laava {{manifesto}}`,

  termsP39: `本使用条款和本网站受新南威尔士州法律管辖。您和 
            Laava 均接受新南威尔士州和澳大利亚联邦法院的
            专属管辖权。`,

  termsP40: "您可以通过以下方式联系 Laava：",

  contact: "联系人",
  privacyOfficer: "隐私专员",
  address: "地址",
  phone: "电话",

  laavaPrivacyManifesto: "Laava 隐私宣言",
  privacyManifestoIntroduction: "Laava - 隐私宣言",
  ourApproach: "我们处理您的隐私方法",
  whatInformationScan: "扫描 Laava 智能指纹时会收集哪些信息？",
  whatInformationDetect: "当我们检测到可疑活动时，会收集哪些信息？",
  howDoesLaava: "我们如何使用Cookie",
  sharingInformation: "我们分享哪些信息？",

  privacyManifestoP1:
    "本文档是我们的隐私宣言，其中概述了我们在使用浏览器或微信 Laava 扫描仪扫描 Laava 智能指纹 时收集信息的政策。",

  privacyManifestoP2: `我们有单独的 Laava 隐私政策，其中规定了我们在其他业务情况下如何处理您的个人信息，例如，如果您是潜在投资者、
                       合作伙伴、客户、供应商或服务提供商的代表，或者如果您联系我们的技术 支持或向我们提交问题或查询。
                       您可以在以下链接找到我们的隐私政策： {{ link }}`,

  privacyManifestoP3: `当您扫描 Laava 智能指纹时，我们不希望收集您的个人信息。 尊重您的隐私是我们最关心的问题，
                       这就是为什么我们不需要姓名、登录、电话号码、电子邮件或任何其他可识别您个人身份的信息。`,

  privacyManifestoP4: `扫描Laava智能指纹是我们工作的核心。我们希望为每个扫描Laava智能指纹的人提供一个安全，私密且无忧的环境。
                       扫描Laava智能指纹意味着没有麻烦，没有间谍的眼睛。`,

  privacyManifestoP5:
    "我们认为，许多公司在消费者隐私方面可以做得更好，我们希望引领市场。 仅仅因为你可以收集数据，并不意味着你应该。",

  privacyManifestoP6: `当您扫描 Laava 智能指纹并更新其状态记录时，我们会捕获您的扫描图像和其他扫描信息或元数据，
                       这些信息或元数据需要验证。 该元数据可能包括扫描源自的 URL、扫描位置（通常派生自 IP 地址或 GPS 位置）
                       以及每次扫描的时间。 我们还在每次扫描时分配并记录匿名扫描仪 ID，并可能使用有限的设备信息，如浏览器和相机设置。`,

  privacyManifestoP7: `重要的是，当您扫描 Laava 智能指纹时，我们收集的所有信息都是匿名的，不能轻易用于识别您（匿名信息）。
                       如果您的扫描图片包含可用于识别您的信息，我们不会寻求这样做。`,

  privacyManifestoP8: "如果您在微信小程序上扫描Laava 智能指纹，则您还将遵守微信的隐私政策。",

  privacyManifestoP9: `为了确保 Laava 是打击假冒和欺诈的有效工具，并保护您的扫描环境安全并保持高质量的服务、性能和安全性，
                       我们使用一系列方法来确定单个 Laava 智能指纹的扫描活动是否可疑。 当我们检测到可疑活动时，
                       我们可能会对扫描活动和元数据进行彻底分析，并将此信息传递给我们的客户和合作伙伴（共同客户）。`,

  privacyManifestoP10: `Laava 在其网站上使用浏览器 Cookie。 浏览器 Cookie 是存储在您的设备上的一小段数据，可帮助
                        网站记住有关您的浏览内容。 Cookie 可帮助我们了解我们网站的哪些部分最受欢迎，从而使我们能够改善您的体验。`,

  privacyManifestoP11: `为了帮助改进我们的网站，某些信息可能会作为统计信息收集，可能包括 IP 地址、浏览器类型、语言和访问时间。 
                        我们网站上使用的工具以不识别您个人身份的方式记录信息。 您的浏览器或设备可能允许您阻止、限制使用或删除 Cookie。`,

  privacyManifestoP12:
    "当您使用基于浏览器的扫描仪扫描 Laava 智能指纹时，可能会使用 Cookie，但不会存储您的扫描历史记录。",

  privacyManifestoP13: "",

  privacyManifestoP14: `客户显然有兴趣分析与物品相关的 Laava 智能指纹扫描记录。 我们与客户分享有关Laava智能指纹的扫描信息，
                        通常以聚合的形式。 当我们与客户共享信息时，我们只告诉他们他们需要知道的，例如扫描他们的 Laava 
                        智能指纹的位置、时间和次数。 由于我们不收集您的个人信息，因此无法与他们共享这些信息。`,

  privacyManifestoP15: `我们还允许客户在您扫描 Laava 智能指纹时以个人方式与您互动。 作为此参与计划的一部分，客户
                        可能会要求您提供个人信息。 我们认为这是伟大的，但在 Laava 共享任何个人信息总是在透明和选
                        择的基础上完成。 你总是负责，能够选择你分享的东西。`,

  privacyManifestoP16: `Laava 不会看到或收集您的个人信息，而是客户会根据客户的隐私政策收集和处理您分享的任何个
                        人信息，而客户将要求您接受这些政策。`,

  privacyManifestoP17: `Laava 可能会与您提供的任何信息共享，如果认为发布适合遵守法律、便利法院诉讼或保护 Laava、
                        本网站用户和其他人的权利、财产或安全。`,

  laavaPrivacyPolicy: "Laava–公司隐私政策",
  privacyPolicyT1: "关于本公司隐私政策",
  privacyPolicyT2: "我们何时收集您的个人信息?",
  privacyPolicyT3: "我们收集和处理什么个人信息?",
  privacyPolicyT4: " 我们为何收集您的个人信息?",
  privacyPolicyT5: "我们可能向谁披露您的个人信息?",
  privacyPolicyT6: "如果我们不能收集您的个人信息会怎样?",
  privacyPolicyT7: "我们网站的访客和 Cookie",
  privacyPolicyT8: "我们如何保护您的个人信息?",
  privacyPolicyT9: "直接营销通讯",
  privacyPolicyT10: "您可以如何访问和更正您的个人信息?",
  privacyPolicyT11: "如果进行个人隐私相关的投诉?",
  privacyPolicyT12: "如何联系我们",

  privacyPolicyP1: `我们认为很多公司都可以在消费者隐私方面做得更好,我们希望能起到带头作用。仅仅因为您可以
收集数据,并不意味着您应该这么做。我们重视和尊重您的隐私,并认真对待对于您个人信息的保
护。`,

  privacyPolicyP2: `本公司隐私政策与您扫描 Laava 指纹时您或您的个人信息所发生的事情无关。我们在扫描 Laava 指
纹时不收集个人信息的政策,载于我们另一份私隐声明内,可在此查阅:[​{{ link }}​]。`,

  privacyPolicyP3: `本公司隐私政策规定 Laava ID Pty Ltd(澳洲公司编号 99 617 775 578)(Laava、我们、我们的、
我方)​在有限的情况下​如何处理您的个人信息,这些情况下我们必须收集个人信息,例如,如果您
申请我们公司的工作岗位,或者寻求向 Laava 投资或(作为合作伙伴、客户、供应商或服务提供商
的代表)与 Laava 合作,或者如果您联系我们的帮助台,或者提交查询。`,

  privacyPolicyP4: `其中概述我们收集的有关您的个人信息、我们何时以及如何使用这些信息。其中还概述您作为个人
的隐私权,以及如果您有任何隐私担忧,如何与我们联系。本隐私政策可不时更改,因此您可能要
定期查看。`,

  privacyPolicyP5: `在我们的隐私声明中规定(可通过以下链接[​插入​]查看),​我们不收集与扫描 Laava 指纹相关的任何
个人信息​。`,

  privacyPolicyP6: `您选择与使用我们 Laava 指纹的品牌(简称“品牌”)所有者共享个人信息时,我们也不查
看或收集您的个人信息。我们的隐私声明中也对这一点予以了规定。`,

  privacyPolicyP7: "但是,在一些有限情况下我们需要直接向您收集您的个人信息,包括当您:",

  privacyPolicyP8: "通过电话、电邮、在线(包括通过我们的网站或帮助台)或当面与我们交流时;",

  privacyPolicyP9: "您向我们申请工作岗位时;",

  privacyPolicyP10: "您申请成为投资者时;或者",

  privacyPolicyP11: "您作为潜在或现有客户、合作伙伴、供应商或服务提供商与我们互动时。",

  privacyPolicyP12: `个人信息是指关于作为确定身份的个人的您,或作为可以合理确定身份的个人的您的任何信息或意
见(无论真假,也无论是否以物质形式记录)。我们收集和处理的此类个人信息包括:`,

  privacyPolicyP13: "联系方式,例如:您的姓名、地址、电话号码、电邮和/或 IP 地址;",

  privacyPolicyP14: `如果您是我们的当前或潜在合作伙伴、客户、供应商或服务提供商,则包括关于您在组织中担任
的角色的信息;`,

  privacyPolicyP15: "如果您申请成为投资者,则包括您与我们共享的财务和其他相关详情;",

  privacyPolicyP16: `如果您向我们申请工作岗位,我们可能向您收集关于您的学历、技能经验、性格、筛选检查和工
作经历的信息;以及`,

  privacyPolicyP17: "您通过我们的帮助台(或在您向我们发送的任何查询中)向我们提供的其他信息。",

  privacyPolicyP18: `我们收集、持有、使用和披露个人信息主要是为了回应查询、考虑您向我们提出的工作岗位申请,
或者考虑您作为我们的投资者、消费者、供应商或合作伙伴。我们也可以出于有关原因收集您的个
人信息,包括:`,

  privacyPolicyP19: "行政目的,包括保存记录和内部报告;",

  privacyPolicyP20: "与您进行交流;",

  privacyPolicyP21: "为了营销目的;以及",

  privacyPolicyP22: "遵守适用法律法规。",

  privacyPolicyP23: `在上述第 2 节列明的我们可以收集您的个人信息的有限情况下,我们可以向第三方披露您的个人信
息。我们可以与之交换个人信息的此类第三方包括:`,

  privacyPolicyP24: `我们的服务提供商,包括会计、审计、法律、银行、付款、交货、数据托管、研究、招聘、保
险、退休金、工资、培训和技术服务;`,

  privacyPolicyP25: `对于向我们申请工作的个人,则包括评审人员和筛选检查提供者(例如,背景、工作资格、适宜
性和犯罪记录调查);以及`,

  privacyPolicyP26: "执法机构和法律规定或许可的其他组织。",

  privacyPolicyP27: `我们不会向任何海外接收人披露您的个人信息。但是,我们可以向安全数据托管提供商存储您的个
人信息,例如,Google,其可以在以下位置存储该等信息
{{ link }}​。如有可能,我们将要求您的个人信息仍存储
在澳大利亚。如果我们需要将您的个人信息披露给任何海外接收人,我们将通过详细说明他们所在
国家的方式,更新本隐私政策。`,

  privacyPolicyP28: "如果您不向我们提供所需的个人信息,我们可能无法:",

  privacyPolicyP29: "考虑您作为雇员入职我方的申请;",

  privacyPolicyP30: "考虑您成为投资者、客户、合作伙伴、供应商或服务提供商的申请;以及",

  privacyPolicyP31: "回应您的查询或信息请求。",

  privacyPolicyP32: `浏览器 Cookie 是存储在您的设备上的一小段数据,帮助网站和移动应用程序记住关于您的事情。尽
管我们在 Laava 移动应用程序中不使用 Cookie,但在我们的网站上使用 Cookie。Cookie 可以帮助
我们了解我们网站的哪些部分最受欢迎,使我们能够集中精力改善网站的那些方面,从而改善您的
体验,并为您提供更好、更直观、更令人满意的体验。您的浏览器或设备可能允许您阻止或限制使
用 Cookie。您也能够从您的浏览器删除 Cookie。`,

  privacyPolicyP33: `若要改善我们通过我们的网站提供的服务,我们可能会从您的浏览器收集某些信息。这些信息是作
为统计信息收集的,包括 IP 地址、浏览器类型、语言和访问次数。许多情况下,我们网站上所使用
的工具以不会识别出您的个人身份的方式记录信息。其他情况下,信息作为个人信息,适用本隐私
政策。`,

  privacyPolicyP34: `我们网站还可以不时使用第三方网站工具,例如:Google Analytics 和在线调查工具。关于 Google
如何收集和处理数据的详情,请参阅 Google 的隐私政策和信息: {{ link }}`,

  privacyPolicyP35: `我们采取一切合理措施保存个人信息,防止其遗失、受到干扰、误用或擅自访问、修改或披露。这
些措施包括访问控制、加密和安全场所。我们以物理形式和电子形式存储个人信息,有时利用第三
方的协助进行存储,例如数据托管服务提供商。`,

  privacyPolicyP36: "如果您选择了接收我方的营销信息,我们可能不时向您发送有关我们服务的直接营销通讯。",

  privacyPolicyP37: `我们可通过电子信息(例如,电子邮件)、网上(例如,通过我们的网站)、邮寄或其他方式与您
联系,除非您随后选择退订或我们受法律限制。您可以使用以下联系方式联系我们,选择退订营销
通讯。`,

  privacyPolicyP38: `我们持有和使用的个人信息的准确性对我们而言至关重要。我们会采取合理措施,确保所处理的个
人信息准确、完整及最新。为了帮助我们确保您的个人信息准确无误,请告知我们您的个人信息中
是否有任何错误或变更。`,

  privacyPolicyP39: `您可以在任何时候通过本隐私政策底部的联系方式与我们的隐私专员联系,要求查阅和更正我们持
有的有关您的个人信息。多数情况下,我们可以迅速、非正式地帮您处理这些请求。在其他情况下
,我们可能需要核实您的身份,并要求您以书面形式提出请求。`,

  privacyPolicyP40: `如果您对我们如何处理您的个人信息有任何疑问,您可以使用本隐私政策底部的联系方式与我们的
隐私专员联系。我们会回复您,告知您由谁处理您的事宜,以及何时可以得到进一步的回复。我们
可能会要求您提供有关您所关注的问题的更多细节。我们也可能需要与其他各方接触或协商,调查
和处理您的问题。我们会记录您的请求和任何解决方案。如果您对我们处理您投诉的方式不满意,
您可以与澳大利亚信息专员办公室联系。`,

  privacyPolicyP41: "如果您对本隐私政策有任何疑问或意见,请随时与我们联系:",
  problemsTitle: "指纹问题",
  problemsIntro:
    "如果 Laava Smart Fingerprint 出现损坏、伪造或扫描时遇到问题，您可以联系我们，我们会尽力帮您解决问题。",
  tryScanningAgain: "再次尝试扫描",
  emailUs: "发送电子邮件给我们",
  troubleDetectingFingerprint: "检测指纹时遇到问题",
  makeSureFingerprint: "确保指纹处<strong>光线充足</strong>且<strong>对焦清晰</strong>",
  ensureInternet: "确保您的<strong>互联网连接</strong>良好",
  tryAgain: "再试一次",
  logIn: "登录",
  account: "帐户",
  emailAddress: "电子邮件地址",
  password: "密码",
  submit: "提交",
  thereWasAProblemLoggingIn: "登录时出现问题，请重试。",
  loggedInAs: "已登录为",
  logOut: "注销"
}

export default zh
