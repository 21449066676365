const pt = {
  pageTitle: "Laava Scanner",
  pageDescription: "Escaneie a sua Laava Smart Fingerprint™ para garantir que o seu produto é genuíno.",
  loading: "Carregando",
  startingScanner: "Iniciando o scanner",
  privacyPolicy: "Ao usar a Laava, você concorda com a nossa",
  cookiesPolicy: "Politica de cookies",
  privacyAndCookies: "Privacidade e Cookies",
  termsOfUse: "Termos de Uso",
  accept: "Aceitar",
  copyrightContent:
    "LAAVA, SMART FINGERPRINTS, IMPRESSÕES DIGITAIS, AUTENTICAÇÃO DAS COISAS, o cheque da Laava, o logotipo Laava e a LAAVA ???? são marcas comerciais da Laava ID Pty Ltd.",
  titleWarningAllowCamera: "Permita o acesso à câmera.",
  bodyWarningAllowCamera:
    "Este webscanner usa a câmera para escanear Fingerprints. Permita o acesso à câmera para tentar novamente.",
  titleWarningSupportedBrowser: "Abra o {{browser}} para escanear sua Fingerprint.",
  bodyWarningSupportedBrowser: "O navegador que você está usando não é compatível no momento.",
  activateTheCameraOn: "Ative a câmera no {{device}}.",
  thisWebscannerUsesTheCamera: "Este webscanner da web usa a câmera para escanear Fingerprints.",
  scanNow: "Escaneie agora",
  refresh: "Recarregar",
  refreshThePage: "Recarregue a página",
  loadAgain: "Acesse scan.laava.id novamente.",
  selectAllowWhenAsked: "Selecione permitir quando for solicitado o acesso à câmera",
  whenAskedSelectAllow: "Quando solicitado, scan.laava.id gostaria de acessar a câmera, selecione permitir.",
  positionTheCode: "Posicione o código dentro do círculo para escanear",
  moveCloserToQR: "Aproxime-se do código QR",
  moveFurtherFromQR: "Afaste-se do código QR",
  centerQRCode: "Centralize o código QR",
  unblurQrCode: "O código QR está desfocado",
  goodQrCode: "Código QR válido",
  badQrCode: "Nenhum código QR detectado",
  qrCodeError: "Erro no código QR",
  seeSupportedBrowsers: "Abra o navegador",
  supportedBrowsersPageTitle: "Este scanner requer um navegador com suporte para câmera.",
  supportedBrowsersPageBody:
    "O sistema operacional do seu dispositivo determinará quais navegadores da web têm suporte para câmeras.",
  howUnblockCamera: "Como desbloquear a câmera",
  help: "Ajuda",
  supportedBrowsersPageHelpBody: "Se você tiver perguntas ou dúvidas, entre em contato conosco.",
  contactUs: "Entre em contato",
  goToBrowserSettings: "Vá para as configurações do navegador",
  findSettingBrowser:
    "Você pode encontrar as configurações tocando nos três pontos no canto superior direito do navegador.",
  selectSiteSettings: "Selecione as configurações do site",
  foundAdvanced: "Isso pode ser encontrado no subtítulo avançado.",
  selectCamera: "Selecione a câmera",
  selectWebsite: "Selecione 'https://scan.laava.id'",
  changePermission: "Altere a permissão para acessar sua câmera, para permitir.",
  returnToWebscanner: "Retorne ao scanner da web para digitalizar.",
  lookForLaava: "Procure por Laava.",
  simplyScan: "Basta escanear.",
  shopWithAssurance: "Compre com garantia.",
  laavaEnablesBrands:
    "A Laava permite que as marcas ofereçam a você garantia sobre a qualidade de seus produtos, usando tecnologia nova e segura desenvolvida com o CSIRO.",
  aboutLaava: "Sobre a Laava",
  knowYouReGetting: "Saiba que você está obtendo a coisa real e não um substituto",
  laavaIsANewKind:
    "Laava é um novo tipo de código de barras que pode ajudar a garantir que o produto pelo qual você está pagando seja genuíno e a protegê-lo de imitações de qualidade inferior.",
  everyFingerprint: "Cada Fingerprint é completamente única e diferente de qualquer outra",
  aFingerprintDoes:
    "Uma Fingerprint faz muito mais do que apenas indicar qual produto ou lote você está segurando. É uma Fingerprint individual e totalmente exclusiva do produto exato em sua mão.",
  seeBeyondTheLabel: "Veja além do rótulo e saiba mais sobre seus produtos",
  scanningAllowsYou:
    "A digitalização permite que você veja mais informações do que apenas o que está na etiqueta. Descubra informações específicas sobre aquele item exato, como onde foi produzido ou quando foi embalado.",
  ourCompany: "Nossa empresa",
  laavaWasFounded:
    "A Laava foi fundada na Austrália em 2016. O conceito e a solução de tecnologia foram desenvolvidos por especialistas líderes mundiais na área de óptica, blockchains, marketing e insights do consumidor.",
  needHelpScanning: "Precisa de ajuda para escanear?",
  youCanFindDetails:
    "Você pode encontrar detalhes sobre a digitalização, junto com respostas para problemas comuns, em nosso guia de ajuda.",
  getHelp: "Obtenha ajuda com o escaneamento",
  howScan: "Como escanear",
  problemsWithTheFingerprint: "Problemas com a Fingerprint",
  commonProblems: "Problemas comuns",
  getResults:
    "Para obter os melhores resultados, essas dicas podem ajudá-lo a superar alguns problemas comuns que podem afetar a qualidade do seu escaneamento.",
  readyToScan: "Pronto para escanear uma Fingerprint?",
  needMoreHelp: "Precisa de mais ajuda?",
  scanFingerprint: "Escaneie sua Fingerprint",
  bringFingerprint:
    "Traga a Fingerprint para o foco dentro da visão da câmera e segure no lugar até que a Fingerprint seja detectada. Isso pode demorar alguns segundos, dependendo da velocidade da sua conexão.",
  locateSmartFingerprint: "Localize sua Smart Fingerprint™",
  fingerprintsCanBeFound: "As Fingerprints podem ser encontradas em uma variedade de produtos de uso diário.",
  alignTheFingerprint: "Alinhe a Fingerprint usando o guia",
  alignTheGuide: "Alinhe a guia com os anéis interno e externo da Fingerprint.",
  snapPhoto: "Tire uma foto",
  getYourFingerprint: "Coloque sua Fingerprint em foco e mantenha sua mão firme.",
  light: "Luz",
  likeAnyCamera:
    "Como qualquer câmera, os melhores resultados são obtidos com boa iluminação. Certifique-se de que sua Fingerprint está bem iluminada e observe se há reflexos.",
  keepYourHands: "Mantenha suas mãos firmes",
  tryKeepYourHand: "Tente manter sua mão e a câmera imóveis ao tirar a foto, para que a imagem fique nítida.",
  considerTheAngle: "Considere o ângulo",
  scanYourFingerprint:
    "Escaneie sua Fingerprint em uma posição frontal para a câmera e preenchendo a maior parte da visualização (enquanto permanece em foco).",
  watchOutForShadow: "Cuidado com as sombras",
  whenScanning:
    "Ao escanear, certifique-se de que seu telefone não esteja projetando uma sombra escura sobre a Fingerprint.",
  haveAnyQuestion: "Tem alguma pergunta ou feedback? Adoraríamos ouvir de você.",
  email: "Email",
  startingCamera: "Iniciando a câmera",
  searching: "Escaneando",
  analysing: "Analisando",
  sending: "Enviando",
  sendingForVerification: "Enviando para verificação",
  mightTakeAFewMoments: "Pode demorar alguns instantes",
  placeCodeWithinFrame: "Coloque o código dentro do quadro para escanear",
  needHelp: "Precisa de Ajuda?",
  googlePrivacyPolicy: "Política de privacidade do Google",
  laavaPrivacy: "Laava Privacidade",
  laavaHasAPrivacy:
    "Laava tem um {{manifesto}} que explica nossas políticas que afetam você e sua privacidade como um usuário das Smart Fingerprints da Laava. Separadamente, também temos uma {{policy}} que se aplica apenas nas circunstâncias limitadas em que temos que coletar informações pessoais.",
  privacyManifesto: "Manifesto de Privacidade",
  corporatePrivacy: "Política de Privacidade Corporativa",
  laavaWebscannerTerm: "Laava - Termos e Condições do Webscanner",
  general: "Em geral",
  websiteUse: "Uso do site",
  intellectualProperty: "Propriedade intelectual",
  limitionOfLiability: "Limitação de responsabilidade",
  indemnity: "Indenização",
  otherWebsites: "Outros sites e terceiros",
  entireAgreement: "Acordo Total",
  invalidity: "Invalidez e aplicabilidade",
  privacy: "Privacidade",
  applicableLaw: "Lei aplicável",
  howToContact: "Como entrar em contato conosco",
  termsP00:
    "Obrigado por acessar o site da Laava (o site). O site pertence e é operado pela Laava ID Pty Ltd (ABN 99 617 775 578) (Laava).",
  termsP01:
    "O uso do site é regido por estes Termos de uso. Ao acessar e usar o site, você concorda em obedecer a estes Termos de uso.",
  termsP02:
    "A Laava pode alterar parte ou todos estes Termos de Uso a qualquer momento. Os Termos de Uso mais recentes sempre serão publicados no Site. Seu uso subsequente ou continuado do site constituirá sua aceitação de quaisquer alterações feitas a estes Termos de uso. Se você objetar a estes Termos de Uso, ou a quaisquer alterações feitas a eles, você deve interromper o uso do Site.",
  termsP03: "Estes termos foram atualizados pela última vez em 26 de novembro de 2018.",
  termsP1: "Você só pode usar o site ou qualquer conteúdo para fins não comerciais.",
  termsP2: "As informações contidas no site são apenas para fins de informação geral.",
  termsP3:
    "O Site e as informações, links, documentos ou gráficos relacionados contidos no Site (juntos, o Conteúdo) são fornecidos 'no estado em que se encontram'. Qualquer confiança que você depositar no Conteúdo é estritamente por sua própria conta e risco. Você precisa fazer suas próprias investigações para determinar se o Conteúdo é apropriado para o uso pretendido.",
  termsP4:
    "Embora a Laava faça todos os esforços razoáveis ​​para garantir que o site esteja disponível e totalmente operacional em todos os momentos, seu acesso ao site pode ser suspenso sem aviso prévio em caso de falha do sistema, manutenção ou qualquer motivo fora do controle da Laava.",
  termsP5: "Laava pode alterar ou descontinuar, temporária ou permanentemente, o:",
  termsP6: "Site, ou qualquer Conteúdo, recurso ou serviço no Site; ou",
  termsP7: "o caminho ou localização de um link,",
  termsP8:
    "a qualquer momento sem aviso prévio. Laava se isenta de qualquer responsabilidade em conexão com essas, ou semelhantes, alterações, descontinuidades ou interrupções do site, ou qualquer parte do site.",
  termsP9:
    "Laava reserva-se o direito de encerrar o seu acesso ao Site e impedir o acesso sem aviso prévio ou notificação em resposta a violações destes Termos de Uso por você mesmo, violações de quaisquer leis aplicáveis ​​ou atividades suspeitas.",
  termsP10: "Ao usar o site, você concorda em não:",
  termsP11: "fornecer qualquer informação falsa ou enganosa ",
  termsP12: "usar o site de uma forma que viole, ou que possa violar, qualquer lei ou regulamento",
  termsP13: "usar o site de uma forma que possa fazer com que a Laava viole qualquer lei ou regulamento",
  termsP14: "usar o site de uma forma que reflita desfavoravelmente para a Laava ou qualquer outro terceiro",
  termsP15:
    "coletar informações sobre outros usuários do site para qualquer finalidade que não seja expressamente autorizada por nós",
  termsP16: "tomar qualquer medida que, na opinião da Laava, imponha uma carga excessiva à infraestrutura do Site;",
  termsP17:
    "distribuir um vírus de computador ou qualquer programa ou código semelhante que possa interromper ou desativar o site",
  termsP18:
    "usar um dispositivo, qualquer software ou processo, ou outro meio automatizado para recuperar, raspar, copiar ou indexar o Site ou qualquer Conteúdo para qualquer propósito; ou",
  termsP19:
    "recuperar, raspar, copiar ou indexar o site ou qualquer conteúdo com a finalidade de criar um banco de dados de informações imobiliárias.",
  termsP20:
    "O site e toda a propriedade intelectual do conteúdo do site, incluindo (sem limitação) qualquer texto, gráfico, fotografia, logotipos ou marcas registradas são propriedade ou licenciados para a Laava, a menos que indicado de outra forma.",
  termsP21:
    "Exceto quando necessário para visualizar o Conteúdo do Site, onde permitido por lei, ou onde você obteve a permissão expressa por escrito da Laava, você não pode reproduzir, recompilar, descompilar, desmontar, fazer engenharia reversa, criar trabalhos derivados de, executar, modificar, adaptar , publicar, armazenar, usar, transmitir eletronicamente ou de outra forma, distribuir ou de qualquer outra forma explorar, por qualquer meio, no todo ou em parte, o Site ou o Conteúdo do Site para outros fins que não o seu próprio uso pessoal e não comercial .",
  termsP22: "O uso do site é feito por sua própria conta e risco.",
  termsP23:
    "Na extensão máxima permitida por lei, a Laava se isenta de todas as representações, garantias ou garantias, expressas ou implícitas, incluindo, mas não se limitando a:",
  termsP24: "a integridade, precisão, confiabilidade, adequação ou disponibilidade com relação ao site ou o conteúdo",
  termsP25: "que o Site e o Conteúdo estarão livres de erros, ou que os defeitos serão corrigidos",
  termsP26: "que o seu acesso ao site será ininterrupto, seguro ou livre de erros",
  termsP27: "que o site está livre de vírus ou quaisquer outros componentes prejudiciais; e",
  termsP28: "a qualidade, precisão ou adequação à finalidade de quaisquer sites vinculados ou material de terceiros.",
  termsP29:
    "Até a extensão máxima permitida por lei, a Laava e seus diretores, funcionários e agentes não são responsáveis ​​por qualquer perda ou dano de qualquer tipo (incluindo perdas indiretas) de qualquer forma causada, seja em contrato, ato ilícito incluindo negligência, estatuto ou de outra forma decorrente em conexão com o site ou o conteúdo.",
  termsP30:
    "Nada nestes Termos de Uso tem a intenção de excluir, restringir ou modificar direitos que você possa ter sob qualquer lei (incluindo na Austrália, a Lei do Consumidor Australiana) que não possa ser excluída, restringida ou modificada por acordo. Se estes Termos de Uso forem regidos pela Lei do Consumidor australiana, nossa responsabilidade para com você pelo não cumprimento de uma garantia do consumidor em relação a quaisquer serviços é limitada ao re-fornecimento dos serviços ou ao pagamento por esse novo fornecimento.",
  termsP31:
    "Você indeniza a Laava em relação a qualquer responsabilidade incorrida pela Laava por quaisquer perdas, responsabilidades ou danos, despesas, incluindo, sem limitação, taxas legais e contábeis razoáveis, qualquer que seja a causa, que sejam sofridas pela Laava como resultado de sua violação destes Termos de Uso ou seu uso do site.",
  termsP32: "O site pode conter links para sites de terceiros, anúncios ou informações nesses sites.",
  termsP33:
    "Laava não tem controle sobre a natureza, conteúdo e disponibilidade de sites de terceiros. A inclusão de quaisquer links para outros sites não implica necessariamente um patrocínio, endosso ou aprovação desses sites ou das opiniões expressas neles.",
  termsP34:
    "A Laava não se responsabiliza pelo conteúdo desses sites. Qualquer uso de sites e informações de terceiros é por sua própria conta e risco.",
  termsP35:
    "Estes Termos de Uso representam todo o acordo entre você e a Laava em relação ao uso e acesso ao Site e ao uso e acesso ao Conteúdo nele contido. Nenhum outro termo deve ser incluído nestes Termos de Uso, exceto quando exigido por qualquer legislação da Comunidade da Austrália ou de qualquer Estado ou Território da Austrália. Todos os termos implícitos, exceto aqueles implícitos por estatuto e que não podem ser expressamente excluídos, estão expressamente excluídos.",
  termsP36:
    "Se qualquer provisão destes Termos de Uso for inválida sob a lei de qualquer jurisdição, então a provisão é aplicável naquela jurisdição na medida em que não seja inválida, seja em termos separáveis ​​ou não.",
  termsP37:
    "Todas as informações pessoais que a Laava coleta de você serão tratadas de acordo com a Lei de Privacidade de 1988 (Cth) e a {{policy}}",
  termsP38:
    "Para obter mais informações sobre como a Laava lida com suas informações pessoais quando você escaneia as Fingerprints da Laava, consulte o {{manifesto}}",
  termsP39:
    "A Laava reserva-se o direito de, a qualquer momento e de tempos em tempos, modificar, interromper temporária ou permanentemente o Scanner de site ou qualquer parte dele, por qualquer motivo. Laava se isenta de qualquer responsabilidade como resultado de qualquer descontinuação ou interrupção do Scanner de site, ou qualquer parte dele",
  termsP40: "Você pode entrar em contato com a Laava via: ",
  contact: "Contato",
  privacyOfficer: "Oficial de Privacidade",
  address: "Endereço",
  phone: "Telefone",
  laavaPrivacyManifesto: "Laava Manifesto de Privacidade",
  privacyManifestoIntroduction: "Laava - Manifesto de Privacidade",
  ourApproach: "Nossa abordagem para sua privacidade",
  whatInformationScan: "Que informações são coletadas ao escanear uma Fingerprint da Laava?",
  whatInformationDetect: "Que informações são coletadas quando detectamos atividades suspeitas?",
  howDoesLaava: "Como a Laava usa cookies?",
  sharingInformation: "Partilhando informação",
  privacyManifestoP1:
    "Este documento é o nosso Manifesto de Privacidade, que descreve nossa política em relação à coleta de informações quando você escaneia uma Laava Smart Fingerprint usando um navegador ou scanner WeChat Laava.",
  privacyManifestoP2:
    "Temos um {{link}} separado que define como lidamos com suas informações pessoais em outras circunstâncias de negócios, como se você se candidatar a uma posição conosco, se você for um potencial investidor, representante de um parceiro, cliente, fornecedor ou nosso provedor de serviços, ou se você entrar em contato com nosso help desk ou enviar uma pergunta ou consulta conosco.",
  privacyManifestoP3:
    "Não desejamos coletar nenhuma das suas informações pessoais ao escanear as Laava Smart Fingerprints. Respeitar a sua privacidade é a nossa principal preocupação, por isso não exigimos nomes, logins, números de telefone, e-mails ou qualquer outra informação que o identifique pessoalmente.",
  privacyManifestoP4:
    "Escanear as Smart Fingerprints da Laava está no centro de tudo o que fazemos. Queremos fornecer um ambiente seguro, privado e sem preocupações para todos que escaneiam as Smart Fingerprints da Laava. Escanear as Smart Fingerprints significa nenhum incômodo e nenhum olhar indiscreto.",
  privacyManifestoP5:
    "Acreditamos que muitas empresas podem fazer melhor no que diz respeito à privacidade do consumidor e queremos liderar o caminho. Só porque você pode coletar dados, não significa que você deva.",
  privacyManifestoP6:
    "Quando você escaneia uma Laava Smart Fingerprint, capturamos uma imagem de sua digitalização e outras informações de digitalização, ou metadados, necessários para autenticar a Laava Smart Fingerprint e atualizar seu registro de status. Esses metadados podem incluir a URL de onde se originou o scan, o local do scan(geralmente derivado de um endereço IP ou localização GPS) e a hora de cada scan. Também atribuímos e registramos uma ID de scanner anônima a cada scan e podemos usar informações limitadas do dispositivo, como configurações do navegador e da câmera.",
  privacyManifestoP7:
    "É importante ressaltar que todas as informações que coletamos quando você escaneia uma Laava Smart Fingerprint são anônimas e não podem ser facilmente usadas para identificá-lo (informações anônimas). Se a imagem da sua digitalização incluir informações que possam ser usadas para identificá-lo, não faremos o possível para fazê-lo.",
  privacyManifestoP8:
    "Se escanear uma Laava Smart Fingerprint em nosso miniprograma WeChat, você também estará sujeito à política de privacidade do WeChat.",
  privacyManifestoP9:
    "Para garantir que o Laava seja uma ferramenta eficaz contra falsificação e fraude, e para manter seu ambiente de escaneamento seguro e manter uma alta qualidade de serviço, desempenho e segurança, usamos uma variedade de métodos para determinar se a atividade de digitalização de uma Laava Smart Fingerprint individual é suspeito. Quando detectamos atividades suspeitas, podemos realizar uma análise completa da atividade de scan e metadados, bem como repassar essas informações aos nossos clientes e parceiros (clientes em conjunto).",
  privacyManifestoP10:
    "A Laava usa cookies do navegador em seu site. Um cookie de navegador é uma pequena porção de dados que é armazenada em seu dispositivo para ajudar os sites a se lembrar de coisas sobre sua navegação. Os cookies nos ajudam a ver quais partes do nosso site são mais populares, o que nos permite melhorar a sua experiência.",
  privacyManifestoP11:
    "Para ajudar a melhorar nosso site, certas informações podem ser coletadas como informações estatísticas e podem incluir endereço IP, tipo de navegador, idioma e horários de acesso. As ferramentas usadas em nosso site registram informações de uma forma que não o identifica como indivíduo. Seu navegador ou dispositivo pode permitir que você bloqueie, limite o uso ou remova cookies.",
  privacyManifestoP12:
    "Quando você escaneia Laava Smart Fingerprints usando um scanner baseado em navegador, cookies podem ser usados, mas seu histórico de escaneamento não será armazenado.",
  privacyManifestoP13:
    "Nosso site também pode usar ferramentas de sites de terceiros de vez em quando, por exemplo, Google Analytics e ferramentas de pesquisa online. Para obter mais informações sobre como o Google coleta e processa dados, consulte a política de privacidade do Google e suas informações em {{link}}",
  privacyManifestoP14:
    "Os clientes estão obviamente interessados ​​em analisar os registros de scans das Laava Smart Fingerprints associados a seus itens. Compartilhamos informações de scans das Laava Smart Fingerprints com os clientes, geralmente de forma agregada. Quando compartilhamos informações com os clientes, estamos apenas dizendo a eles o que eles precisam saber, como onde, quando e quantas vezes sua Laava Smart Fingerprint foi escaneada. Como não coletamos suas informações pessoais, não podemos compartilhá-las.",
  privacyManifestoP15:
    "Também permitimos que os clientes interajam com você em uma base pessoal quando você escaneia uma Laava Smart Fingerprint. Como parte desse compromisso, os clientes podem solicitar suas informações pessoais. Achamos isso ótimo, mas na Laava o compartilhamento de qualquer informação pessoal é sempre feito de forma transparente e opcional. Você está sempre no comando e pode escolher o que quer compartilhar.",
  privacyManifestoP16:
    "A Laava não verá ou coletará suas informações pessoais; em vez disso, quaisquer informações pessoais que você compartilhar serão coletadas e tratadas por nossos clientes de acordo com suas políticas de privacidade, que você deverá aceitar.",
  privacyManifestoP17:
    "A Laava pode compartilhar qualquer informação que você fornecer quando acreditar que a liberação é apropriada para o cumprimento da lei, para facilitar os procedimentos judiciais ou proteger os direitos, propriedade ou segurança da Laava, dos usuários deste site e de outros.",
  laavaPrivacyPolicy: "Política de privacidade da Laava",
  privacyPolicyT1: "Sobre esta Política de Privacidade Corporativa",
  privacyPolicyT2: "Quando coletamos suas informações pessoais?",
  privacyPolicyT3: "Que informações pessoais coletamos e tratamos?",
  privacyPolicyT4: "Por que coletamos suas informações pessoais?",
  privacyPolicyT5: "A quem podemos divulgar suas informações pessoais?",
  privacyPolicyT6: "E se não pudermos coletar suas informações pessoais?",
  privacyPolicyT7: "Visitantes do nosso site e cookies",
  privacyPolicyT8: "Como protegemos as suas informações pessoais?",
  privacyPolicyT9: "Comunicações de marketing direto",
  privacyPolicyT10: "Como você pode acessar e corrigir suas informações pessoais?",
  privacyPolicyT11: "Como você pode fazer uma reclamação de privacidade?",
  privacyPolicyT12: "Como entrar em contato conosco",
  privacyPolicyP1:
    "Acreditamos que muitas empresas podem fazer melhor no que diz respeito à privacidade do consumidor e queremos liderar o caminho. Só porque você pode coletar dados, não significa que você deve. Valorizamos e respeitamos sua privacidade e levamos a sério a proteção de suas informações pessoais.",
  privacyPolicyP2:
    "Esta Política de Privacidade Corporativa NÃO trata do que acontece com você ou com suas informações pessoais quando você escaneia uma scans das Laava Smart Fingerprin. Nossa política de não coletar informações pessoais ao escanear uma Laava Smart Fingerprint é abordada em nosso {{link}} separado",
  privacyPolicyP3:
    "Esta Política de Privacidade Corporativa define como a Laava ID Pty Ltd (ABN 99617 775 578) (Laava, nós, nossos, nós) trata suas informações pessoais nas circunstâncias limitadas em que temos que coletar informações pessoais, como se você se inscrever para um trabalhe conosco, ou busque investir em, ou trabalhar com a Laava (como um representante de um parceiro, cliente, fornecedor ou provedor de serviços), ou se você entrar em contato com nosso help desk ou enviar uma consulta.",
  privacyPolicyP4:
    "Ele descreve as informações pessoais que coletamos sobre você, quando e como as usamos. Ele também descreve seus direitos de privacidade como indivíduo e como entrar em contato conosco se tiver alguma preocupação com a privacidade. Esta Política de Privacidade pode mudar de tempos em tempos e, portanto, você pode querer revisá-la regularmente.",
  privacyPolicyP5:
    "Conforme estabelecido em nosso Manifesto de Privacidade, não coletamos nenhuma informação pessoal relacionada à digitalização de uma Laava Smart Fingerprint.",
  privacyPolicyP6:
    "Também não vemos ou coletamos suas informações pessoais quando você opta por compartilhá-las com proprietários de marcas que usam nossas Smart Fingerprint. Isso também é abordado em nosso Manifesto de Privacidade.",
  privacyPolicyP7:
    "No entanto, existem algumas circunstâncias limitadas em que precisamos coletar suas informações pessoais diretamente de você, incluindo quando você:",
  privacyPolicyP8:
    "comunique-se conosco por telefone, e-mail, online (inclusive por meio de nosso site ou help desk) ou pessoalmente",
  privacyPolicyP9: "quando você se candidata a uma posição conosco",
  privacyPolicyP10: "quando você se inscreve para se tornar um investidor;ou",
  privacyPolicyP11:
    "quando você interage conosco como um cliente potencial ou contínuo, parceiro, fornecedor ou provedor de serviços.",
  privacyPolicyP12:
    "Informações pessoais são quaisquer informações ou opiniões (verdadeiras ou não, e registradas em uma forma material ou não) sobre você como um indivíduo identificado ou um indivíduo que seja razoavelmente identificável. Os tipos de informações pessoais que coletamos e tratamos podem incluir:",
  privacyPolicyP13: "informações de contato, como seu nome, endereço, número de telefone, e-mail e / ou endereço IP",
  privacyPolicyP14:
    "informações sobre sua função dentro de sua organização, se você for um parceiro existente ou potencial, cliente, fornecedor ou prestador de serviços nosso",
  privacyPolicyP15:
    "detalhes financeiros e outros detalhes relevantes que você compartilha conosco se você se inscrever para ser um investidor",
  privacyPolicyP16:
    "se você se candidatar a uma posição conosco, poderemos coletar informações sobre suas qualificações, habilidades, experiência, caráter, verificações de triagem e histórico de empregos; e",
  privacyPolicyP17:
    "outras informações que você nos fornece por meio de nosso help desk (ou em qualquer consulta que você nos enviar).",
  privacyPolicyP18:
    "Coletamos, mantemos, usamos e divulgamos informações pessoais principalmente para responder a dúvidas, para considerar sua candidatura a um cargo conosco ou para considerá-lo um investidor, cliente, fornecedor ou parceiro nosso. Também podemos coletar suas informações pessoais por motivos relacionados, incluindo:",
  privacyPolicyP19: "fins administrativos, incluindo manutenção de registros e relatórios internos",
  privacyPolicyP20: "para se comunicar com você",
  privacyPolicyP21: "para fins de marketing; e",
  privacyPolicyP22: "para cumprir as leis e regulamentos aplicáveis.",
  privacyPolicyP23:
    "Nas circunstâncias limitadas listadas na seção 2 acima, onde coletamos suas informações pessoais, podemos divulgar suas informações pessoais a terceiros. Os tipos de terceiros com os quais trocamos informações pessoais incluem:",
  privacyPolicyP24:
    "nossos prestadores de serviços, incluindo contabilidade, auditoria, jurídico, bancário, pagamento, entrega, hospedagem de dados, pesquisa, recrutamento, seguro, aposentadoria, folha de pagamento, treinamento e serviços de tecnologia",
  privacyPolicyP25:
    "para pessoas que se candidatam para trabalhar conosco, árbitros e provedores de verificação de triagem (por exemplo, para antecedentes, elegibilidade para trabalhar, idoneidade e verificação de antecedentes criminais); e",
  privacyPolicyP26: "para agências de aplicação da lei e outras organizações quando exigido ou permitido por lei.",
  privacyPolicyP27:
    "Não divulgaremos suas informações pessoais a nenhum destinatário estrangeiro. No entanto, podemos armazenar suas informações pessoais com provedores de hospedagem de dados seguros, como o Google, que pode armazenar essas informações nos seguintes locais: ({{link}}). Sempre que possível, exigiremos que suas informações pessoais permaneçam armazenadas na Austrália. Se precisarmos divulgar suas informações pessoais a quaisquer destinatários no exterior, atualizaremos esta Política de Privacidade especificando os países nos quais eles estão localizados.",
  privacyPolicyP28: "Se você não nos fornecer as informações pessoais quando necessário, podemos não ser capazes de:",
  privacyPolicyP29: "considere sua inscrição para se juntar a nós como funcionário",
  privacyPolicyP30:
    "considere sua candidatura para se tornar um investidor, cliente, parceiro; fornecedor ou prestador de serviços; e",
  privacyPolicyP31: "responder às suas perguntas ou pedidos de informação.",
  privacyPolicyP32:
    "Um cookie de navegador é um pequeno pedaço de dados que é armazenado em seu dispositivo para ajudar sites e aplicativos móveis a lembrar coisas sobre você. Embora não usemos cookies no aplicativo móvel Laava, usamos cookies em nosso site. Os cookies nos ajudam a ver quais partes do nosso site são mais populares, o que nos permite nos concentrar em melhorar os aspectos do site que irão melhorar a sua experiência e fornecer a você uma experiência melhor, mais intuitiva e satisfatória. Seu navegador ou dispositivo pode permitir que você bloqueie ou limite o uso de cookies. Você também pode remover cookies de seu navegador.",
  privacyPolicyP33:
    "Para ajudar a melhorar o serviço que oferecemos por meio de nosso site, certas informações podem ser coletadas de seu navegador. Essas informações são coletadas como informações estatísticas e incluem o endereço IP, tipo de navegador, idioma e horários de acesso. Em muitos casos, as ferramentas usadas em nosso site registram informações de uma forma que não o identifica como indivíduo. Em outros casos, as informações podem ser informações pessoais, caso em que esta Política de Privacidade se aplicará.",
  privacyPolicyP34:
    "Nosso site também pode usar ferramentas de sites de terceiros de vez em quando, por exemplo, Google Analytics e ferramentas de pesquisa online. Para obter mais informações sobre como o Google coleta e processa dados, consulte a política de privacidade do Google e suas informações em: ({{link}}).",
  privacyPolicyP35:
    "Tomamos todas as medidas razoáveis para manter as informações pessoais protegidas contra perda, interferência, uso indevido ou acesso não autorizado, modificação ou divulgação. Essas etapas podem incluir controles de acesso, criptografia e instalações seguras. Armazenamos informações pessoais em formato físico e eletrônico, às vezes com a ajuda de terceiros, como provedores de hospedagem de dados.",
  privacyPolicyP36:
    "De vez em quando, se você optou por receber nossas informações de marketing, podemos enviar-lhe comunicações de marketing diretas sobre nossos serviços.",
  privacyPolicyP37:
    "Podemos contatá-lo por mensagens eletrônicas (por exemplo, e-mail), online (por exemplo, por meio de nosso website), por correio e por outros meios, a menos que você posteriormente desative o serviço ou estejamos sujeitos a restrições legais. Você pode cancelar o recebimento de comunicações de marketing, entrando em contato conosco através dos dados de contato abaixo.",
  privacyPolicyP38:
    "A precisão das informações pessoais que mantemos e usamos é importante para nós. Tomamos medidas razoáveis ​​para garantir que as informações pessoais que tratamos sejam precisas, completas e atualizadas. Para nos ajudar a manter suas informações pessoais corretas, informe-nos se houver algum erro ou alteração em suas informações pessoais.",
  privacyPolicyP39:
    "Você pode solicitar acesso e correção das informações pessoais que mantemos sobre você a qualquer momento, entrando em contato com nosso Diretor de Privacidade por meio dos dados de contato na parte inferior desta Política de Privacidade. Na maioria dos casos, podemos ajudá-lo de forma rápida e informal com essas solicitações. Em outros casos, podemos precisar verificar sua identidade e solicitar que você faça sua solicitação por escrito.",
  privacyPolicyP40:
    "Você pode entrar em contato com nosso Diretor de Privacidade usando os detalhes de contato na parte inferior desta Política de Privacidade se tiver alguma dúvida sobre como tratamos suas informações pessoais. Responderemos para informá-lo sobre quem tratará do seu caso e quando você pode esperar uma resposta posterior. Podemos solicitar detalhes adicionais sobre sua preocupação, e podemos precisar entrar em contato ou consultar outras partes para investigar e lidar com o seu problema. Manteremos registros de sua solicitação e de qualquer resolução. Se não estiver satisfeito com a maneira como tratamos sua reclamação, você pode entrar em contato com o Office of the Australian Information Commissioner.",
  privacyPolicyP41:
    "Se você tiver dúvidas ou reclamações em relação ao WebScanner  ou a estes Termos e Condições, pode entrar em contato com a Laava em:",
  problemsTitle: "Problemas com a Fingerprint",
  problemsIntro:
    "Se a Laava Smart Fingerprint ™ parecer danificada ou se você estiver tendo problemas para escanear, entre em contato e faremos o possível para ajudá-lo a resolver o problema.",
  tryScanningAgain: "Tente escanear novamente",
  emailUs: "Envie-nos um email",
  troubleDetectingFingerprint: "Estamos tendo problemas para detectar uma Fingerprint",
  makeSureFingerprint: "Certifique-se de que a Fingerprint está bem iluminada e em foco",
  ensureInternet: "Certifique-se de que sua conexão com a Internet esteja boa",
  tryAgain: "Tente novamente",
  logIn: "Entrar",
  account: "Conta",
  emailAddress: "Endereço de e-mail",
  password: "Senha",
  submit: "Enviar",
  thereWasAProblemLoggingIn: "Houve um problema ao entrar. Por favor, tente novamente.",
  loggedInAs: "Logado como",
  logOut: "Sair"
}

export default pt
