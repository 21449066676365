const es = {
  pageTitle: "Escáner Laava",
  pageDescription: "Escanee su Laava Smart Fingerprint™ para asegurarse de que su producto sea genuino.",
  loading: "Cargando",
  startingScanner: "Iniciando escáner",
  privacyPolicy: "Al usar Laava, usted acepta nuestros",
  cookiesPolicy: "Política de cookies",
  privacyAndCookies: "Privacidad y cookies",
  termsOfUse: "Términos de Uso",
  accept: "Aceptar",
  copyrightContent:
    "LAAVA, HUELLAS DACTILARES INTELIGENTES, HUELLAS DACTILARES, AUTENTICACIÓN DE COSAS, el cheque Laava, el logo Laava, y LAAVA ???? son marcas comerciales de Laava ID Pty Ltd.",
  titleWarningAllowCamera: "Permita el acceso a la cámara.",
  bodyWarningAllowCamera:
    "Este escáner web utiliza la cámara para escanear huellas dactilares. Permita el permiso para acceder a la cámara para volver a intentarlo.",
  titleWarningSupportedBrowser: "Abra el {{browser}} para escanear su huella digital.",
  bodyWarningSupportedBrowser: "El navegador que está utilizando no es compatible actualmente.",
  activateTheCameraOn: "Activa la cámara en {{device}}.",
  thisWebscannerUsesTheCamera: "Este escáner web utiliza la cámara para escanear huellas dactilares.",
  scanNow: "Escanear ahora",
  refresh: "Actualizar",
  refreshThePage: "Recarga la página",
  loadAgain: "Cargue scan.laava.id nuevamente.",
  selectAllowWhenAsked: "Seleccione permitir cuando se le solicite acceso a la cámara",
  whenAskedSelectAllow: "Cuando se le pregunte a scan.laava.id le gustaría acceder a la cámara, seleccione permitir.",
  positionTheCode: "Coloque el código dentro del marco para escanear",
  moveCloserToQR: "Acérquese más al código QR",
  moveFurtherFromQR: "Aléjese del código QR",
  centerQRCode: "Centre el código QR",
  unblurQrCode: "El código QR está muy borroso",
  goodQrCode: "Buen código QR",
  badQrCode: "No se detectó ningún código QR",
  qrCodeError: "Error en el código QR",
  seeSupportedBrowsers: "Iniciar navegador",
  supportedBrowsersPageTitle: "Este escáner requiere un navegador compatible con cámaras.",
  supportedBrowsersPageBody:
    "El sistema operativo de su dispositivo determinará qué navegadores web son compatibles con la cámara.",
  howUnblockCamera: "Cómo desbloquear la cámara",
  help: "Ayuda",
  supportedBrowsersPageHelpBody: "Si tiene preguntas o inquietudes, comuníquese con nosotros.",
  contactUs: "Contáctenos",
  goToBrowserSettings: "Ir a la configuración del navegador",
  findSettingBrowser:
    "Puede encontrar la configuración tocando los tres puntos en la esquina superior derecha del navegador.",
  selectSiteSettings: "Seleccione la configuración del sitio",
  foundAdvanced: "Esto se puede encontrar bajo el subtítulo avanzado.",
  selectCamera: "Seleccionar cámara",
  selectWebsite: "Seleccione 'https://scan.laava.id'",
  changePermission: "Cambie el permiso para acceder a su cámara, para permitir.",
  returnToWebscanner: "Regrese al escáner web para escanear.",
  lookForLaava: "Busca a Laava.",
  simplyScan: "Simplemente escanee.",
  shopWithAssurance: "Compre con Garantía.",
  laavaEnablesBrands:
    "Laava permite a las marcas brindarle garantías sobre la calidad de sus productos utilizando tecnología nueva y segura desarrollada con CSIRO.",
  aboutLaava: "Sobre Laava",
  knowYouReGetting: "Sepa que está obteniendo lo real y no un sustituto",
  laavaIsANewKind:
    "Laava es un nuevo tipo de código de barras que puede ayudar a garantizar que el producto que está pagando sea genuino y lo mantenga a salvo de imitaciones de menor calidad.",
  everyFingerprint: "Cada huella dactilar es completamente única y diferente a cualquier otra",
  aFingerprintDoes:
    "Una huella dactilar hace mucho más que simplemente indicar qué producto o lote tiene. Es una huella dactilar individual y completamente única para el producto exacto que tiene en la mano.",
  seeBeyondTheLabel: "Vea más allá de la etiqueta y sepa más sobre sus productos",
  scanningAllowsYou:
    "Escanear le permite ver más información que solo lo que está en la etiqueta. Encuentre información específica sobre ese artículo exacto, como dónde se produjo o cuándo se empaquetó.",
  ourCompany: "Nuestra compañía",
  laavaWasFounded:
    "Laava se fundó en Australia en 2016. El concepto y la solución tecnológica han sido desarrollados por expertos líderes mundiales en el campo de la óptica, cadenas de bloques, marketing y conocimiento del consumidor.",
  needHelpScanning: "¿Necesitas ayuda para escanear?",
  youCanFindDetails:
    "Puede encontrar detalles sobre cómo escanear, junto con respuestas a problemas comunes, en nuestra guía de ayuda.",
  getHelp: "Obtener ayuda con el escaneo",
  howScan: "como escanear",
  problemsWithTheFingerprint: "Problemas con la huella dactilar",
  commonProblems: "Problemas comunes",
  getResults:
    "Para obtener los mejores resultados, estos consejos pueden ayudarlo a superar algunos problemas comunes que pueden afectar la calidad de su escaneo.",
  readyToScan: "¿Listo para escanear una huella digital?",
  needMoreHelp: "Necesitas más ayuda?",
  scanFingerprint: "Escanea tu huella dactilar",
  bringFingerprint:
    "Enfoca la huella digital dentro de la vista de la cámara y mantenla en su lugar hasta que se detecte la huella digital. Esto puede tomar unos minutos dependiendo de la velocidad de su conexión.",
  locateSmartFingerprint: "Localice su Smart Fingerprint™",
  fingerprintsCanBeFound: "Las huellas dactilares se pueden encontrar en una variedad de productos cotidianos.",
  alignTheFingerprint: "Alinee la huella dactilar usando la guía",
  alignTheGuide: "Alinee la guía con los anillos interior y exterior del Fingerprint.",
  snapPhoto: "Sacar una foto",
  getYourFingerprint: "Enfoca tu huella dactilar y mantén la mano firme.",
  light: "Luz",
  likeAnyCamera:
    "Como cualquier cámara, los mejores resultados se obtienen con buena luz. Asegúrese de que su huella digital esté bien iluminada y esté atento a los reflejos.",
  keepYourHands: "Mantén tus manos firmes",
  tryKeepYourHand: "Trate de mantener la mano y la cámara quietas cuando tome la foto, para que la imagen sea clara.",
  considerTheAngle: "Considere el ángulo",
  scanYourFingerprint:
    "Escanee su huella dactilar en una posición que esté frente a la cámara y llene la mayor parte de la vista (mientras permanece enfocado).",
  watchOutForShadow: "Cuidado con las sombras",
  whenScanning: "Al escanear, asegúrese de que su teléfono no proyecte una sombra oscura sobre la huella digital.",
  haveAnyQuestion: "¿Tiene alguna pregunta o comentario? Nos encantaría saber de usted.",
  email: "Correo electrónico",
  startingCamera: "Cámara de inicio",
  searching: "Exploración",
  analysing: "analizando",
  sending: "Enviando",
  sendingForVerification: "Envío para verificación",
  mightTakeAFewMoments: "Puede tomar unos momentos",
  placeCodeWithinFrame: "Coloque el código dentro del marco para escanear",
  needHelp: "¿Necesitas ayuda?",
  googlePrivacyPolicy: "Política de privacidad de Google",
  laavaPrivacy: "Laava Privacidad",
  laavaHasAPrivacy:
    "Laava tiene un {{ manifesto }} que explica nuestras políticas que te afectan a ti y a tu privacidad como usuario de Laava Fingerprints. Por separado, también tenemos una {{ policy }} que solo se aplica en las circunstancias limitadas en las que tenemos que recopilar información personal.",
  privacyManifesto: "Manifiesto de Privacidad",
  corporatePrivacy: "Política de Privacidad Corporativa",
  laavaWebscannerTerm: "Términos y condiciones de Laava – Webscanner",
  general: "General",
  websiteUse: "uso del sitio web",
  intellectualProperty: "Propiedad intelectual",
  limitionOfLiability: "Limitación de responsabilidad",
  indemnity: "Indemnidad",
  otherWebsites: "Otros sitios web y de terceros",
  entireAgreement: "Acuerdo completo",
  invalidity: "Nulidad y oponibilidad",
  privacy: "Privacidad",
  applicableLaw: "Ley aplicable",
  howToContact: "Cómo contactarnos",
  termsP00:
    "Gracias por acceder al sitio web de Laava (el sitio web). El sitio web es propiedad y está operado por Laava ID Pty Ltd (ABN 99 617 775 578) (Laava).",
  termsP01:
    "Su uso del sitio web se rige por estos Términos de uso. Al acceder y utilizar el sitio web, usted acepta estar sujeto a estos Términos de uso.",
  termsP02:
    "Laava puede cambiar parte o la totalidad de estos Términos de uso en cualquier momento. Los Términos de uso más actualizados siempre se publicarán en el sitio web. Su uso posterior o continuado del sitio web constituirá su aceptación de cualquier cambio realizado en estos Términos de uso. Si se opone a estos Términos de uso, o a cualquier cambio realizado en ellos, debe dejar de usar el sitio web.",
  termsP03: "Estos términos se actualizaron por última vez el 26 de noviembre de 2018.",
  termsP1: "Solo puede usar el sitio web o cualquier contenido para fines no comerciales.",
  termsP2: "La información contenida en el sitio web es solo para fines de información general.",
  termsP3:
    "El sitio web y la información, los enlaces, los documentos o los gráficos relacionados contenidos en el sitio web (juntos, el contenido) se proporcionan 'tal cual' . Cualquier confianza que deposite en el Contenido es estrictamente bajo su propio riesgo. Debe realizar sus propias consultas para determinar si el Contenido es apropiado para su uso previsto.",
  termsP4:
    "Si bien Laava hace todos los esfuerzos razonables para garantizar que el sitio web esté disponible y en pleno funcionamiento en todo momento , su acceso al sitio web puede suspenderse sin previo aviso en caso de falla del sistema, mantenimiento o cualquier motivo fuera del control de Laava.",
  termsP5: "Laava puede cambiar o discontinuar, ya sea temporal o permanentemente, el:",
  termsP6: "Sitio web, o cualquier Contenido, función o servicio en el Sitio web; o",
  termsP7: "la ruta o ubicación de un enlace,",
  termsP8:
    "en cualquier momento sin previo aviso. Laava se exime de cualquier responsabilidad en relación con estos, o cambios similares, discontinuidades o interrupciones del sitio web, o cualquier parte del sitio web.",
  termsP9:
    "Laava se reserva el derecho de cancelar su acceso al sitio web y evitar el acceso posterior sin previo aviso o aviso en respuesta a incumplimientos de estos Términos de uso por su parte, incumplimientos de cualquier ley aplicable o actividad sospechosa.",
  termsP10: "Al utilizar el sitio web , acepta no:",
  termsP11: "proporcionar cualquier información falsa, engañosa o engañosa",
  termsP12: "usar el sitio web de una manera que viole, o pueda violar, cualquier ley o reglamento",
  termsP13: "usar el sitio web de una manera que pueda causar que Laava viole cualquier ley o regulación",
  termsP14: "usar el sitio web de una manera que se refleje desfavorablemente en Laava o cualquier otro tercero",
  termsP15:
    "recopilar información sobre otros usuarios del sitio web para cualquier propósito que no sea el expresamente autorizado por nosotros",
  termsP16:
    "tomar cualquier acción que, en opinión de Laava, imponga una carga irrazonable en la infraestructura del sitio web;",
  termsP17:
    "distribuir un virus informático o cualquier programa o código similar que pueda interrumpir o deshabilitar el sitio web",
  termsP18:
    "usar un dispositivo, cualquier software o proceso, u otro medio automatizado para recuperar, raspar, copiar o indexar el sitio web o cualquier contenido para cualquier propósito; o",
  termsP19:
    "recuperar, raspar, copiar o indexar el sitio web o cualquier contenido con el fin de crear una base de datos de información inmobiliaria.",
  termsP20:
    "El sitio web y toda la propiedad intelectual en el contenido del sitio web, incluidos (sin limitación) cualquier texto, gráfico, fotografía, logotipo o marca comercial , son propiedad de Laava, o están autorizados para ello, a menos que se indique lo contrario.",
  termsP21:
    "Excepto cuando sea necesario para ver el Contenido en el sitio web, donde lo permita la ley, o donde haya obtenido el permiso expreso por escrito de Laava, no puede reproducir, recompilar, descompilar, desensamblar, aplicar ingeniería inversa, crear trabajos derivados, realizar, modificar, adaptar , publicar, almacenar, usar, transmitir electrónicamente o de otro modo, distribuir o explotar de cualquier otro modo, por cualquier medio, en su totalidad o en parte, el sitio web o el contenido del sitio web para fines distintos a su uso personal y no comercial .",
  termsP22: "Su uso del sitio web se realiza bajo su propio riesgo.",
  termsP23:
    "En la máxima medida permitida por la ley, Laava renuncia a todas las representaciones, garantías , expresas o implícitas, incluidas, entre otras, las siguientes:",
  termsP24: "la integridad, exactitud, fiabilidad, idoneidad o disponibilidad con respecto al Sitio Web o el Contenido",
  termsP25: "que el Sitio Web y el Contenido estarán libres de errores, o que los defectos serán corregidos",
  termsP26: "que su acceso al Sitio Web será ininterrumpido, seguro o libre de errores",
  termsP27: "que el Sitio Web está libre de virus o cualquier otro componente dañino; y",
  termsP28:
    "la calidad, la precisión o la idoneidad para el propósito de cualquier sitio web vinculado o material de terceros .",
  termsP29:
    "En la medida máxima permitida por la ley, Laava y sus directores, empleados y agentes no son responsables ante usted por ninguna pérdida o daño de ningún tipo (incluidas las pérdidas emergentes), cualquiera que sea su causa, ya sea por contrato, agravio, incluida la negligencia, estatuto o que surja de otro modo. en relación con el Sitio Web o los Contenidos.",
  termsP30:
    "Nada en estos Términos de uso tiene la intención de excluir, restringir o modificar los derechos que pueda tener bajo cualquier ley (incluyendo en Australia, la Ley del Consumidor de Australia) que no pueden ser excluidos, restringidos o modificados por acuerdo. Si estos Términos de uso se rigen por la Ley del Consumidor de Australia, nuestra responsabilidad ante usted por el incumplimiento de una garantía del consumidor con respecto a cualquier servicio se limita al reabastecimiento de los servicios o al pago de dicho reabastecimiento.",
  termsP31:
    "Usted indemniza a Laava con respecto a cualquier responsabilidad incurrida por Laava por cualquier pérdida, responsabilidad o daño, gastos, incluidos, entre otros, honorarios legales y contables razonables, cualquiera que sea su causa, que sufra Laava como resultado de su incumplimiento de estos Términos de uso. o su uso del sitio web.",
  termsP32: "El sitio web puede contener enlaces a sitios web de terceros, anuncios o información en esos sitios web.",
  termsP33:
    "Laava no tiene control sobre la naturaleza, el contenido y la disponibilidad de los sitios web de terceros . La inclusión de enlaces a otros sitios web no implica necesariamente un patrocinio, respaldo o aprobación de esos sitios web o las opiniones expresadas en ellos.",
  termsP34:
    "Laava no asume ninguna responsabilidad por el contenido de esos sitios web. Cualquier uso de sitios web e información de terceros es bajo su propio riesgo.",
  termsP35:
    "Estos Términos de uso representan el acuerdo completo entre usted y Laava con respecto a su uso y acceso al sitio web y su uso y acceso al contenido del mismo. No se debe incluir ningún otro término en estos Términos de uso, excepto cuando sea requerido por cualquier legislación de la Mancomunidad de Australia o cualquier Estado o Territorio de Australia. Todos los términos implícitos, excepto aquellos implícitos por ley y que no pueden excluirse expresamente, quedan expresamente excluidos.",
  termsP36:
    "Si alguna disposición de estos Términos de uso no es válida según la ley de cualquier jurisdicción, entonces la disposición es aplicable en esa jurisdicción en la medida en que no sea inválida, ya sea en términos divisibles o no.",
  termsP37:
    "Toda la información personal que Laava recopila de usted se manejará de acuerdo con la Ley de Privacidad de 1988 ( Cth ) y la {{polícy}}",
  termsP38:
    "Para obtener más información sobre cómo Laava maneja su información personal cuando escanea las huellas dactilares de Laava, consulte el {{manifesto}}",
  termsP39:
    "Laava se reserva el derecho en cualquier momento y de vez en cuando de modificar, descontinuar temporal o permanentemente el Escáner de sitios web, o cualquier parte del mismo, por cualquier motivo. Laava se exime de cualquier responsabilidad como resultado de cualquier descontinuación o interrupción del Escáner de sitios web, o cualquier parte del mismo.",
  termsP40: "Puedes contactar con Laava a través de:",
  contact: "Contacto",
  privacyOfficer: "Oficial de Privacidad",
  address: "Dirección",
  phone: "Teléfono",
  laavaPrivacyManifesto: "Manifiesto de privacidad de Laava",
  privacyManifestoIntroduction: "Laava – Manifiesto de Privacidad",
  ourApproach: "Nuestro enfoque a su privacidad",
  whatInformationScan: "¿Qué información se recopila cuando escaneo una huella dactilar de Laava?",
  whatInformationDetect: "¿Qué información se recopila cuando detectamos actividad sospechosa?",
  howDoesLaava: "¿Cómo utiliza Laava las cookies?",
  sharingInformation: "Compartiendo información",
  privacyManifestoP1:
    "Este documento es nuestro Manifiesto de privacidad que describe nuestra política con respecto a la recopilación de información cuando escanea una huella digital inteligente de Laava con un navegador o un escáner WeChat Laava.",
  privacyManifestoP2:
    "Tenemos un {{ link }} separado que establece cómo manejamos su información personal en otras circunstancias comerciales, como si solicita un puesto con nosotros, si es un inversionista potencial, representante de un socio, cliente, proveedor o nuestro proveedor de servicios, o si se comunica con nuestra mesa de ayuda o envía una pregunta o consulta con nosotros.",
  privacyManifestoP3:
    "No deseamos recopilar su información personal cuando escanea las huellas dactilares inteligentes de Laava. Respetar su privacidad es nuestra principal preocupación, por lo que no requerimos nombres, inicios de sesión , números de teléfono, correos electrónicos o cualquier otra información que lo identifique personalmente.",
  privacyManifestoP4:
    "El escaneo de las huellas dactilares inteligentes de Laava es el núcleo de todo lo que hacemos. Queremos brindar un entorno seguro, privado y sin preocupaciones para todos los que escanean una huella digital inteligente de Laava. Escanear las huellas dactilares inteligentes de Laava significa que no hay molestias ni miradas indiscretas.",
  privacyManifestoP5:
    "Creemos que muchas empresas pueden hacerlo mejor con respecto a la privacidad del consumidor y queremos liderar el camino. El hecho de que pueda recopilar datos no significa que deba hacerlo.",
  privacyManifestoP6:
    "Cuando escanea una huella digital inteligente de Laava, capturamos una imagen de su escaneo y otra información de escaneo, o metadatos, necesarios para autenticar la huella digital inteligente de Laava y actualizar su registro de estado. Esos metadatos pueden incluir la URL desde la que se originó el escaneo, la ubicación del escaneo (generalmente derivada de una dirección IP o ubicación GPS) y la hora de cada escaneo. También asignamos y registramos una identificación de escáner anonimizada con cada escaneo y podemos usar información limitada del dispositivo, como la configuración del navegador y la cámara.",
  privacyManifestoP7:
    "Es importante destacar que toda la información que recopilamos cuando escanea una huella digital inteligente de Laava se anonimiza y no se puede utilizar fácilmente para identificarlo ( información anonimizada) . Si la imagen de su escaneo incluye información que podría usarse para identificarlo, no intentaremos hacerlo.",
  privacyManifestoP8:
    "Si escanea una huella digital inteligente de Laava en nuestro miniprograma de WeChat , también estará sujeto a la política de privacidad de WeChat.",
  privacyManifestoP9:
    "Para garantizar que Laava sea una herramienta eficaz contra la falsificación y el fraude, y para mantener su entorno de escaneo seguro y mantener una alta calidad de servicio, rendimiento y seguridad, utilizamos una variedad de métodos para determinar si la actividad de escaneo de un Laava Smart Fingerprint individual es sospechoso. Cuando detectamos actividad sospechosa, podemos realizar un análisis exhaustivo de la actividad de escaneo y los metadatos, así como transmitir esta información a nuestros clientes y socios (en conjunto, Clientes) .",
  privacyManifestoP10:
    "Laava utiliza cookies de navegador en su sitio web. Una cookie del navegador es una pequeña porción de datos que se almacena en su dispositivo para ayudar a los sitios web a recordar cosas sobre su navegación. Las cookies nos ayudan a ver qué partes de nuestro sitio web son las más populares, lo que nos permite mejorar su experiencia.",
  privacyManifestoP11:
    "Para ayudar a mejorar nuestro sitio web, se puede recopilar cierta información como información estadística y puede incluir la dirección IP, el tipo de navegador, el idioma y los tiempos de acceso. Las herramientas utilizadas en nuestro sitio web registran información de una manera que no lo identifica como individuo. Su navegador o dispositivo puede permitirle bloquear, limitar el uso o eliminar cookies.",
  privacyManifestoP12:
    "Cuando escanea las huellas dactilares inteligentes de Laava con un escáner basado en navegador, se pueden usar cookies, pero su historial de escaneo no se almacenará.",
  privacyManifestoP13:
    "Nuestro sitio web también puede utilizar herramientas de sitios web de terceros de vez en cuando, por ejemplo, Google Analytics y herramientas de encuestas en línea. Para obtener más información sobre cómo Google recopila y procesa los datos, consulte la política de privacidad de Google y su información en {{ link }}",
  privacyManifestoP14:
    "Los clientes obviamente están interesados en analizar los registros de escaneo de Laava Smart Fingerprints asociados con sus artículos. Compartimos información de escaneo sobre Laava Smart Fingerprints con los Clientes, generalmente en forma agregada. Cuando compartimos información con los Clientes, solo les decimos lo que necesitan saber, como dónde, cuándo y cuántas veces se escaneó su Laava Smart Fingerprint. Dado que no recopilamos su información personal, no podemos compartirla con ellos.",
  privacyManifestoP15:
    "También permitimos que los clientes interactúen con usted de forma personal cuando escanea una huella digital inteligente de Laava. Como parte de este compromiso, los Clientes pueden solicitarle información personal. Creemos que eso es genial, pero en Laava, compartir cualquier información personal siempre se hace de forma transparente y voluntaria. Siempre estás a cargo y puedes elegir lo que compartes.",
  privacyManifestoP16:
    "Laava no verá ni recopilará su información personal; en cambio, cualquier información personal que comparta será recopilada y manejada por nuestros clientes de acuerdo con sus políticas de privacidad, que se le pedirá que acepte.",
  privacyManifestoP17:
    "Laava puede compartir cualquier información que proporcione cuando crea que la divulgación es apropiada para el cumplimiento de la ley, para facilitar los procedimientos judiciales o proteger los derechos, la propiedad o la seguridad de Laava, los usuarios de este sitio web y otros.",
  laavaPrivacyPolicy: "Política de privacidad de Laava",
  privacyPolicyT1: "Acerca de esta Política de Privacidad Corporativa",
  privacyPolicyT2: "¿Cuándo recopilamos su información personal?",
  privacyPolicyT3: "¿Qué información personal recopilamos y manejamos?",
  privacyPolicyT4: "¿Por qué recopilamos su información personal?",
  privacyPolicyT5: "¿A quién podemos divulgar su información personal?",
  privacyPolicyT6: "¿Qué sucede si no podemos recopilar su información personal?",
  privacyPolicyT7: "Visitantes de nuestro sitio web y cookies",
  privacyPolicyT8: "¿Cómo protegemos tu información personal?",
  privacyPolicyT9: "Comunicaciones de marketing directo",
  privacyPolicyT10: "¿Cómo puede acceder y corregir su información personal?",
  privacyPolicyT11: "¿Cómo se puede presentar una queja de privacidad?",
  privacyPolicyT12: "Cómo contactarnos",
  privacyPolicyP1:
    "Creemos que muchas empresas pueden hacerlo mejor con respecto a la privacidad del consumidor y queremos liderar el camino. El hecho de que pueda recopilar datos no significa que deba hacerlo. Valoramos y respetamos su privacidad y nos tomamos muy en serio la protección de su información personal.",
  privacyPolicyP2:
    "Esta Política de privacidad corporativa NO se trata de lo que le sucede a usted o a su información personal cuando escanea una huella digital de Laava. Nuestra política de no recopilar información personal al escanear las huellas dactilares de Laava está cubierta en nuestro {{ enlace }} separado",
  privacyPolicyP3:
    "Esta Política de privacidad corporativa establece cómo Laava ID Pty Ltd (ABN 99617 775 578) (Laava, nosotros, nuestro, nosotros) maneja su información personal en las circunstancias limitadas en las que tenemos que recopilar información personal, como si solicita un trabajar con nosotros, o busca invertir o trabajar con Laava (como representante de un socio, cliente, proveedor o proveedor de servicios), o si se comunica con nuestra mesa de ayuda o envía una consulta.",
  privacyPolicyP4:
    "Describe la información personal que recopilamos sobre usted, cuándo y cómo la usamos. También describe sus derechos de privacidad como individuo y cómo comunicarse con nosotros si tiene alguna inquietud sobre la privacidad. Esta Política de privacidad puede cambiar de vez en cuando, por lo que es posible que desee revisarla periódicamente.",
  privacyPolicyP5:
    "Como se establece en nuestro Manifiesto de privacidad, no recopilamos ninguna información personal en relación con el escaneo de una huella digital de Laava.",
  privacyPolicyP6:
    "Tampoco vemos ni recopilamos su información personal cuando elige compartir información personal con los propietarios de las marcas que utilizan nuestras Huellas dactilares de Laava (Marcas). Esto también está cubierto en nuestro Manifiesto de Privacidad.",
  privacyPolicyP7:
    "Sin embargo, existen algunas circunstancias limitadas en las que necesitamos recopilar su información personal directamente de usted, incluso cuando usted:",
  privacyPolicyP8:
    "comunicarse con nosotros por teléfono, correo electrónico, en línea (incluso a través de nuestro sitio web o servicio de asistencia), o en persona",
  privacyPolicyP9: "cuando solicita un puesto con nosotros",
  privacyPolicyP10: "cuando solicita convertirse en inversionista; o",
  privacyPolicyP11:
    "cuando interactúa con nosotros como cliente, socio, proveedor o proveedor de servicios potencial o actual.",
  privacyPolicyP12:
    "La información personal es cualquier información u opinión (ya sea verdadera o no, y registrada en forma material o no) sobre usted como una persona identificada o una persona razonablemente identificable. Los tipos de información personal que recopilamos y manejamos pueden incluir:",
  privacyPolicyP13:
    "información de contacto, como su nombre, dirección, número de teléfono, correo electrónico y/o dirección IP",
  privacyPolicyP14:
    "información sobre su función dentro de su organización, si usted es un socio, cliente, proveedor o proveedor de servicios existente o potencial",
  privacyPolicyP15:
    "detalles financieros y otros detalles relevantes que comparte con nosotros si solicita ser un inversionista",
  privacyPolicyP16:
    "si solicita un puesto con nosotros, podemos recopilar información sobre sus calificaciones, habilidades, experiencia, carácter, controles de selección e historial de empleo; y",
  privacyPolicyP17:
    "otra información que nos proporcione a través de nuestra mesa de ayuda (o en cualquier consulta que nos envíe).",
  privacyPolicyP18:
    "Recopilamos, conservamos, usamos y divulgamos información personal principalmente para responder consultas, considerar su solicitud para un puesto con nosotros o considerarlo como un inversionista, cliente, proveedor o socio nuestro. También podemos recopilar su información personal por motivos relacionados, que incluyen:",
  privacyPolicyP19:
    "fines administrativos, incluido el mantenimiento de registros y la elaboración de informes internos",
  privacyPolicyP20: "para comunicarme contigo",
  privacyPolicyP21: "con fines de marketing; y",
  privacyPolicyP22: "para cumplir con las leyes y regulaciones aplicables.",
  privacyPolicyP23:
    "En las circunstancias limitadas enumeradas en la sección 2 anterior en las que hemos recopilado su información personal, podemos divulgar su información personal a terceros. Los tipos de terceros con los que intercambiamos información personal incluyen:",
  privacyPolicyP24:
    "nuestros proveedores de servicios, incluidos los servicios de contabilidad, auditoría, legales, bancarios, pagos, entrega, alojamiento de datos, investigación, contratación, seguros, jubilación, nómina, capacitación y tecnología",
  privacyPolicyP25:
    "para las personas que solicitan trabajar con nosotros, los árbitros y los proveedores de control de selección ( por ejemplo , antecedentes, elegibilidad para trabajar, idoneidad y verificación de antecedentes penales); y",
  privacyPolicyP26:
    "a los organismos encargados de hacer cumplir la ley y otras organizaciones cuando lo exija o lo permita la ley.",
  privacyPolicyP27:
    "No divulgaremos su información personal a ningún destinatario en el extranjero. Sin embargo, podemos almacenar su información personal con proveedores de alojamiento de datos seguros como Google, que pueden almacenar esa información en las siguientes ubicaciones: ({{link}}). Siempre que sea posible, solicitaremos que su información personal permanezca almacenada en Australia. Si necesitamos divulgar su información personal a destinatarios en el extranjero, actualizaremos esta Política de privacidad especificando los países en los que se encuentran.",
  privacyPolicyP28: "Si no nos proporciona la información personal cuando se le solicite, es posible que no podamos:",
  privacyPolicyP29: "considere su solicitud para unirse a nosotros como empleado",
  privacyPolicyP30:
    "considere su solicitud para convertirse en inversor, cliente, socio; proveedor o prestador de servicios; y",
  privacyPolicyP31: "responder a sus consultas o solicitudes de información.",
  privacyPolicyP32:
    "Una cookie del navegador es una pequeña porción de datos que se almacena en su dispositivo para ayudar a los sitios web y las aplicaciones móviles a recordar cosas sobre usted. Aunque no usamos cookies en la aplicación móvil de Laava, sí usamos cookies en nuestro sitio web. Las cookies nos ayudan a ver qué partes de nuestro sitio web son las más populares, lo que nos permite centrarnos en mejorar aquellos aspectos del sitio web que mejorarán su experiencia y brindarle una experiencia mejor, más intuitiva y satisfactoria. Su navegador o dispositivo puede permitirle bloquear o limitar el uso de cookies. También puede eliminar las cookies de su navegador.",
  privacyPolicyP33:
    "Para ayudar a mejorar el servicio que brindamos a través de nuestro sitio web, se puede recopilar cierta información de su navegador. Esta información se recoge como información estadística e incluye la dirección IP, tipo de navegador, idioma y tiempos de acceso. En muchos casos, las herramientas utilizadas en nuestro sitio web registran información de una manera que no lo identifica como individuo. En otros casos, la información puede ser información personal, en cuyo caso se aplicará esta Política de Privacidad.",
  privacyPolicyP34:
    "Nuestro sitio web también puede utilizar herramientas de sitios web de terceros de vez en cuando, por ejemplo, Google Analytics y herramientas de encuestas en línea. Para obtener más información sobre cómo Google recopila y procesa los datos, consulte la política de privacidad de Google y su información en: ( {{ link }}).",
  privacyPolicyP35:
    "Tomamos todas las medidas razonables para mantener la información personal protegida contra pérdidas, interferencias, uso indebido o acceso, modificación o divulgación no autorizados. Estos pasos pueden incluir controles de acceso, cifrado y locales seguros. Almacenamos información personal tanto en forma física como electrónica, a veces con la ayuda de terceros, como proveedores de alojamiento de datos.",
  privacyPolicyP36:
    "De vez en cuando, si ha optado por recibir nuestra información de marketing, podemos enviarle comunicaciones de marketing directo con respecto a nuestros servicios.",
  privacyPolicyP37:
    "Es posible que nos comuniquemos con usted mediante mensajes electrónicos ( p. ej ., correo electrónico), en línea (p. ej., a través de nuestro sitio web), por correo postal y por otros medios, a menos que posteriormente se excluya o estemos sujetos a restricciones legales. Puede optar por no recibir comunicaciones de marketing poniéndose en contacto con nosotros utilizando los datos de contacto a continuación.",
  privacyPolicyP38:
    "La precisión de la información personal que tenemos y usamos es importante para nosotros. Tomamos medidas razonables para garantizar que la información personal que manejamos sea precisa, completa y actualizada . Para ayudarnos a mantener su información personal precisa, infórmenos si hay algún error o cambio en su información personal.",
  privacyPolicyP39:
    "Puede solicitar acceso y corrección de la información personal que tenemos sobre usted en cualquier momento comunicándose con nuestro Oficial de privacidad a través de los detalles de contacto en la parte inferior de esta Política de privacidad. En la mayoría de los casos, podemos ayudarlo de manera rápida e informal con estas solicitudes. En otros casos, es posible que necesitemos verificar su identidad y pedirle que haga su solicitud por escrito.",
  privacyPolicyP40:
    "Puede ponerse en contacto con nuestro Oficial de privacidad utilizando los datos de contacto que se encuentran en la parte inferior de esta Política de privacidad si tiene alguna inquietud sobre cómo hemos manejado su información personal. Responderemos para informarle quién se encargará de su asunto y cuándo puede esperar una respuesta adicional. Es posible que le solicitemos detalles adicionales con respecto a su inquietud, y es posible que debamos comprometernos o consultar con otras partes para investigar y tratar su problema. Mantendremos registros de su solicitud y cualquier resolución. Si no está satisfecho con la forma en que hemos tratado su queja, puede comunicarse con la Oficina del Comisionado de Información de Australia.",
  privacyPolicyP41:
    "Si tiene alguna pregunta, queja o reclamo en relación con el Escáner de sitios web o estos Términos y condiciones, puede comunicarse con Laava en:",
  problemsTitle: "Problemas con la huella dactilar",
  problemsIntro:
    "Si Laava Smart Fingerprint™ parece dañado o tiene problemas para escanear, puede comunicarse y haremos todo lo posible para ayudarlo a resolver el problema.",
  tryScanningAgain: "Intenta escanear de nuevo",
  emailUs: "Envíanos un correo electrónico",
  troubleDetectingFingerprint: "Tenemos problemas para detectar una huella digital",
  makeSureFingerprint: "Asegúrese de que la huella digital esté bien iluminada y enfocada",
  ensureInternet: "Asegúrese de que su conexión a Internet sea buena",
  tryAgain: "Intentar otra vez",
  logIn: "Iniciar sesión",
  account: "Cuenta",
  emailAddress: "Dirección de correo electrónico",
  password: "Contraseña",
  submit: "Enviar",
  thereWasAProblemLoggingIn: "Hubo un problema al iniciar sesión. Por favor, inténtelo de nuevo.",
  loggedInAs: "Sesión iniciada como",
  logOut: "Cerrar sesión"
}

export default es
