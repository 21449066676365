import React, { Suspense, useState, useLayoutEffect, useEffect, lazy } from 'react'
import { BrowserRouter as Router, Routes, Route, Outlet, useLocation, Navigate } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

// import routes from "./routes"
import GlobalStyle from './styles/global-style'
import { theme } from './styles/theme'
import { SessionProvider } from './services/context/SessionContext'
import { DisplayProvider } from './services/context/DisplayContext'
import GAListener from './components/ga-listener'
import LaavaHelmet from './components/helmet'
import DesktopPage from './components/desktop-page'
import Loader from './components/loader'
import ScrollToTop from '../src/services/scroll-to-top'
import useSupportedBrowser from '../src/hooks/use-supported-browser'
import { BrowserErrorModal } from './pages/scan/errorModal'
import DeviceIdManager from './services/device-id'
import TrackingManager from './services/tracking'
import ScannerEventManager from './services/event-manager'
import MonitoringManager from './services/monitoring'
import NavBar from './components/navbar'
import Offset from './components/navbar/navbar-offset'
import Footer from './components/footer'

import { Provider } from 'react-redux'
import { store, persistor } from './store'

import { PersistGate } from 'redux-persist/lib/integration/react'

const App = () => {
  const [sessionId, setSessionId] = useState()
  const [deviceId, setDeviceId] = useState()
  const [showIntro, setShowIntro] = useState(true)
  const [navEnabled, setNavEnabled] = useState(true)
  const [footerEnabled, setFooterEnabled] = useState(true)
  const [privacyAlertEnabled, setPrivacyAlertEnabled] = useState(false)
  const [isEmbedded, setIsEmbedded] = useState(false)
  const [browserChecked, setBrowserChecked] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const { isBrowserSupported, supportedBrowser } = useSupportedBrowser()
  const sessionState = {
    showIntro,
    setShowIntro,
    sessionId,
    setSessionId,
    deviceId,
    setDeviceId,
  }

  const getDeviceId = () => {
    const deviceIdManager = new DeviceIdManager()
    const deviceId = deviceIdManager.getDeviceId()

    setDeviceId(deviceId)
  }

  useEffect(() => {
    getDeviceId()
  }, [])

  useEffect(() => {
    if (window.parent && window.parent.location) {
      if (window.location !== window.parent.location) {
        setIsEmbedded(true)
      }
      const params = new URLSearchParams(window.location.search)

      if (params.get('n') === '0') {
        setNavEnabled(false)
      }
      if (params.get('f') === '0') {
        setFooterEnabled(false)
      }
      if (params.get('pa') === '0') {
        setPrivacyAlertEnabled(false)
      } else {
        setPrivacyAlertEnabled(true)
      }
    }
  }, [isEmbedded, navEnabled])

  useEffect(() => {
    if (!browserChecked && deviceId) {
      if (!isBrowserSupported) {
        const trackingManager = new TrackingManager()
        trackingManager.track({
          event: 'Unsupported Browser Warning',
          data: {
            deviceId,
            sessionId,
          },
        })
      }
      setBrowserChecked(true)
    }
  }, [browserChecked, deviceId, isBrowserSupported, sessionId])

  useEffect(() => {
    sessionId &&
      MonitoringManager.record({
        sessionId,
      })
  }, [sessionId])

  useLayoutEffect(() => {
    window.addEventListener('resize', () => {
      setScreenWidth(window.innerWidth)
    })
  })

  useEffect(() => {
    if (privacyAlertEnabled === true) {
      // Check if the script has already been added
      const existingScript = document.querySelector('script[data-cbid="619011e6-bcba-484d-ac96-1e174e75f18e"]')
      if (!existingScript) {
        var script = document.createElement('script')
        script.src = 'https://consent.cookiebot.com/uc.js'
        script.type = 'text/javascript'
        script.async = true
        script.setAttribute('data-cbid', '619011e6-bcba-484d-ac96-1e174e75f18e')
        script.setAttribute('type', 'text/javascript')
        document.head.appendChild(script)
      }
    }
  }, [privacyAlertEnabled])

  window.ScannerEventManager = ScannerEventManager

  const Scan = lazy(() => import('./pages/scan'))
  const ScanResults = lazy(() => import('./pages/scan-results'))
  const ProjectRedirect = lazy(() => import('./pages/project-redirect'))
  const ActivateCamera = lazy(() => import('./pages/activate-camera'))
  const SupportedBrowsers = lazy(() => import('./pages/supported-browsers'))
  const HowScan = lazy(() => import('./pages/how-scan'))
  const Problems = lazy(() => import('./pages/problems'))
  const ContactUs = lazy(() => import('./pages/contact-us'))
  const About = lazy(() => import('./pages/about'))
  const Account = lazy(() => import('./pages/account'))
  const Help = lazy(() => import('./pages/help'))
  const Privacy = lazy(() => import('./pages/privacy'))
  const Terms = lazy(() => import('./pages/terms'))
  const Page404 = lazy(() => import('./pages/page404'))
  const PrivacyManifesto = lazy(() => import('./pages/privacy-manifesto'))
  const PrivacyPolicy = lazy(() => import('./pages/privacy-policy'))
  const Timeout = lazy(() => import('./pages/timeout'))
  const AppLauncher = lazy(() => import('./pages/app-launcher'))
  const DirectScan = lazy(() => import('./pages/direct-scan'))

  // because the router has changed in React 18, we need to change how we conditionally render the footer and navbar offset

  const ConditionalOffset = () => {
    const location = useLocation()
    if (location.pathname === '/' || location.pathname === '/timeout') {
      return null
    } else {
      return <Offset />
    }
  }

  const DaRedirect = () => {
    const location = useLocation()
    return (
      <Navigate
        to={{
          pathname: '/',
          search: location.search ? `cfg=dalsf&${location.search.substring(1)}` : 'cfg=dalsf',
        }}
        replace
      />
    )
  }

  const ConditionalFooter = () => {
    const location = useLocation()
    if (location.pathname === '/' || location.pathname === '/timeout') {
      return null
    } else {
      return <Footer />
    }
  }

  const renderChild = () => {
    if (screenWidth > 800) {
      return <DesktopPage />
    }

    if (isBrowserSupported) {
      return (
        <>
          {navEnabled && (
            <>
              <NavBar />
              <ConditionalOffset />
            </>
          )}

          <Outlet />
          <Routes>
            <Route path="/" exact element={<Scan />} />
            <Route path="/scan/t/:tagId" exact element={<DirectScan />} />
            <Route path="/t/:tagId" exact element={<DirectScan />} />
            <Route path="/p/:projectId" exact element={<ProjectRedirect />} />
            <Route path="/scan-results" exact element={<ScanResults />} />
            <Route path="/scan-results/:scanId" exact element={<ScanResults />} />
            <Route path="/help/how-scan" exact element={<HowScan />} />
            <Route path="/help/problems" exact element={<Problems />} />
            <Route path="/help/contact-us" exact element={<ContactUs />} />
            <Route path="/about" exact element={<About />} />
            <Route path="/account" exact element={<Account />} />
            <Route path="/da" element={<DaRedirect />} />
            <Route path="/help" exact element={<Help />} />
            <Route path="/help/activate-camera" exact element={<ActivateCamera />} />
            <Route path="/help/supported-browsers" exact element={<SupportedBrowsers />} />
            <Route path="/privacy" exact element={<Privacy />} />
            <Route path="/privacy-manifesto" exact element={<PrivacyManifesto />} />
            <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
            <Route path="/terms" exact element={<Terms />} />
            <Route path="/timeout" exact element={<Timeout />} />
            <Route path="/launch" exact element={<AppLauncher />} />
            <Route path="/01/:gtin/*" exact element={<AppLauncher />} />
            <Route path="*" element={<Page404 />} />
          </Routes>

          {footerEnabled && <ConditionalFooter />}
        </>
      )
    }

    return <BrowserErrorModal browser={supportedBrowser} />
  }

  const displayContextValues = {
    isEmbedded,
    navEnabled,
    footerEnabled,
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <DisplayProvider value={displayContextValues}>
          <SessionProvider value={sessionState}>
            <LaavaHelmet />
            <Suspense fallback={<Loader />}>
              <ThemeProvider theme={theme}>
                <Router>
                  <ScrollToTop />
                  <GlobalStyle />
                  <GAListener>{renderChild()}</GAListener>
                </Router>
              </ThemeProvider>
            </Suspense>
          </SessionProvider>
        </DisplayProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
